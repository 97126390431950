import React, { useEffect, useRef, useState } from "react";
import styles from "./SearchSidebar.module.css";
import CrossIcon from "../../assets/CrossIcon.svg";
import SearchIcon from "../../assets/searchIcon.svg";
import FullStarIcon from "../../assets/RatingFullStarIcon.svg";
import BlankStarIcon from "../../assets/RatingBlankStarIcon.svg";
import { toast } from "react-toastify";
import { getAllProducts } from "../../services/Product";
import { useNavigate } from "react-router-dom";

const SearchSidebar = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const [allProducts, setAllProducts] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(""); // State to track selected category
  const [selectFabric, setSelectFabric] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    if (!search && !selectedCategory && !selectFabric) {
      setSearchResults([]);
      return;
    }
    const results = allProducts?.products?.filter((product) => {
      const matchesCategory = selectedCategory
        ? product.category === selectedCategory
        : true;
      const matchesFabric = selectFabric
        ? product.fabric === selectFabric
        : true;
      const matchesSearch = product.name
        .toLowerCase()
        .includes(search.toLowerCase());
      return matchesCategory && matchesFabric && matchesSearch;
    });
    setSearchResults(results);
  }, [search, selectedCategory, selectFabric]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getAllProducts();
        setAllProducts(products);
      } catch (errorMessage) {
        toast.error(errorMessage);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleNavigate = (product) => {
    onClose();
    navigate(
      `/${product?.category}/${product?.name?.replace(/\s+/g, "-")}/${
        product?.productId
      }?variant=${product?.variants[0]?.sku}`
    );
  };

  return (
    <>
      <div
        className={`${styles.overlay} ${isOpen ? styles.open : ""}`}
        onClick={onClose}
      ></div>
      <div
        ref={sidebarRef}
        className={`${styles.searchSidebar} ${isOpen ? styles.open : ""}`}
      >
        {/* search text and cross icon  */}
        <div className={styles.header}>
          <h2>Search</h2>
          <button onClick={onClose} className={styles.closeButton}>
            <img src={CrossIcon} alt="X" />
          </button>
        </div>
        {/* Dropdown category */}
        <div className={styles.content}>
          <select
            className={styles.dropdown}
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select Category</option>
            <option value="Saree">Saree</option>
            <option value="Dupatta">Dupatta</option>
            <option value="Dress">Dress</option>
            <option value="Lehanga">Lehanga</option>
          </select>
        </div>
        {/* Dropdown fabric */}
        <div className={styles.content}>
          <select
            className={styles.dropdown}
            value={selectFabric}
            onChange={(e) => setSelectFabric(e.target.value)}
          >
            <option value="">Select Fabric</option>
            <option value="Silk">Silk</option>
            <option value="Georagette">Georagette</option>
            <option value="Crep">Crep</option>
            <option value="Rayon">Rayon</option>
          </select>
        </div>
        {/* search input  */}
        <div className={styles.content}>
          <input
            type="text"
            placeholder="Search"
            className={styles.searchInput}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search === "" ? (
            <img src={SearchIcon} alt="search" />
          ) : (
            <img src={CrossIcon} alt="close" onClick={() => setSearch("")} />
          )}
        </div>
        {/* search item */}
        {searchResults?.length > 0 && (
          <div className={styles.searchContent}>
            <p>Search results ({searchResults.length})</p>
            <div className={styles.searchItem}>
              {searchResults.map((product) => (
                <div
                  key={product.productId}
                  onClick={() => handleNavigate(product)}
                  className={styles.searchProduct}
                >
                  <div>
                    <img
                      src={product.variants[0].image}
                      alt={product.name}
                      className={styles.productImg}
                    />
                  </div>
                  <div className={styles.productInfo}>
                    <h3>{product.name}</h3>
                    {/* product review  */}
                    <div className={styles.rating}>
                      <div className={styles.ratingStar}>
                        {[...Array(5)].map((_, index) => (
                          <img
                            key={index}
                            src={
                              index < product.rating
                                ? FullStarIcon
                                : BlankStarIcon
                            }
                            alt="star"
                          />
                        ))}
                      </div>
                      <p>(3 reviews)</p>
                    </div>
                    <div className={styles.productInfoPrices}>
                      <p className={styles.productPrice}>
                        Rs.{product.showPrice}.00
                      </p>
                      <p className={styles.originalPrice}>₹{product?.price}.00</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {searchResults?.length === 0 && search !== "" && (
          <div className={styles.searchContent}>
            <p>Search results ({searchResults.length})</p>
            <p className={styles.noProductFound}>
              No products were found matching your selection.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchSidebar;
