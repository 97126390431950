import { useNavigate } from "react-router-dom";
import styles from "./Policies.module.css";

const Policies = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.policyContainer}>
      <h1 className={styles.topPolicyTitle}>Our Policies</h1>

      {/* Shipping Policy */}
      <div className={styles.policySection} id="shipping">
        <h2 className={styles.policyTitle}>Shipping Policy</h2>
        <h3 className={styles.policySubTitle}>Processing Time</h3>
        <p>
          All orders are processed within 2-3 business days after receiving your
          order confirmation. You will receive a shipping confirmation email
          once your order has shipped.
        </p>

        <h3 className={styles.policySubTitle}>
          Shipping Methods & Delivery Time
        </h3>
        <p>We offer standard and express shipping options:</p>
        <ul>
          <li>Standard Shipping: 5-7 business days</li>
          <li>Express Shipping: 2-3 business days</li>
        </ul>
        <p>Please note that delivery times may vary based on your location.</p>

        <h3 className={styles.policySubTitle}>Shipping Fee</h3>
        <p>
          Shipping costs are calculated during checkout based on weight,
          dimensions, and destination. Free standard shipping is available for
          orders over ₹1,500 within India.
        </p>

        <h3 className={styles.policySubTitle}>International Shipping</h3>
        <p>
          We ship to select international destinations. International orders may
          be subject to import duties and taxes, which are the responsibility of
          the customer.
        </p>

        <h3 className={styles.policySubTitle}>Order Tracking</h3>
        <p>
          Once your order ships, you will receive a tracking number via email.
          You can track your order status through our website or the shipping
          carrier's website.
        </p>
      </div>

      {/* Return & Refund Policy */}
      <div className={styles.policySection} id="return">
        <h2 className={styles.policyTitle}>Return & Refund Policy</h2>

        <h3 className={styles.policySubTitle}>Return Eligibility</h3>
        <p>
          We accept returns within 14 days of delivery for items that are in
          their original condition with tags attached.
        </p>
        <p>The following items cannot be returned:</p>
        <ul>
          <li>Custom-made or personalized items</li>
          <li>Sale items marked as final sale</li>
          <li>Items that have been worn, washed, or altered</li>
        </ul>

        <h3 className={styles.policySubTitle}>Return Process</h3>
        <p>
          To initiate a return, please email us at{" "}
          <a href="mailto:info@bandhejhub.tech">info@bandhejhub.tech</a> with
          your order number and reason for return.
        </p>
        <p>
          Once your return is approved, we will provide instructions for
          shipping the item back to us. Return shipping costs are the
          responsibility of the customer unless the return is due to our error.
        </p>

        <h3 className={styles.policySubTitle}>Refunds</h3>
        <p>
          Once we receive and inspect your return, we will process your refund.
          The refund will be issued to the original payment method within 7-10
          business days.
        </p>
        <p>
          Shipping costs are non-refundable unless the return is due to our
          error (damaged or incorrect item).
        </p>
        <h3 className={styles.policySubTitle}>Exchanges</h3>
        <p>
          If you need to exchange an item for a different size or color, please
          initiate a return and place a new order for the desired item.
        </p>
        <h3 className={styles.policySubTitle}>Damaged or Defective Items</h3>
        <p>
          If you receive a damaged or defective item, please contact us within
          48 hours of delivery with photos of the damage. We will arrange for a
          replacement or refund at no additional cost to you.
        </p>
      </div>

      {/* Privacy Policy */}
      <div className={styles.policySection} id="privacy">
        <h2 className={styles.policyTitle}>Privacy Policy</h2>

        <h3 className={styles.policySubTitle}>Information We Collect</h3>
        <p>
          We collect personal information that you voluntarily provide to us
          when you:
        </p>
        <ul>
          <li>Name, email, phone number, and address</li>
          <li>Order and account details</li>
          <li>Newsletter subscription info</li>
        </ul>

        <h3 className={styles.policySubTitle}>How We Use Your Information</h3>
        <p>We use your information to:</p>
        <ul>
          <li>Process orders</li>
          <li>Improve customer service</li>
          <li>Send updates and promotions</li>
          <li>Improve our website and services</li>
        </ul>
        <h3 className={styles.policySubTitle}>Information Sharing</h3>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your consent, except to trusted third parties
          who assist us in operating our website, conducting our business, or
          servicing you.
        </p>
        <h3 className={styles.policySubTitle}>Cookies</h3>
        <p>
          We use cookies to enhance your browsing experience, analyze site
          traffic, and personalize content. You can choose to disable cookies
          through your browser settings, but this may affect your experience on
          our website.
        </p>

        <h3 className={styles.policySubTitle}>Data Security</h3>
        <p>
          We implement appropriate security measures to protect your personal
          information against unauthorized access, alteration, disclosure, or
          destruction.
        </p>

        <h3 className={styles.policySubTitle}>Your Rights</h3>
        <p>
          You have the right to access, correct, or delete your personal
          information. To exercise these rights, please contact us at{" "}
          <a href="mailto:info@bandhejhub.tech">info@bandhejhub.tech</a>.
        </p>
        <h3 className={styles.policySubTitle}>Policy Updates</h3>
        <p>
          We may update this privacy policy from time to time. Any changes will
          be posted on this page, and the effective date will be updated
          accordingly.
        </p>
      </div>
      {/* Terms of Service  */}
      <div className={styles.policySection} id="terms">
        <h2 className={styles.policyTitle}>Terms of Service</h2>

        <h3 className={styles.policySubTitle}>Overview</h3>
        <p>
          By accessing and using BandhejHub, you agree to comply with and be
          bound by these Terms of Service. If you do not agree with any part of
          these terms, please do not use our website.
        </p>

        <h3 className={styles.policySubTitle}>Products and Pricing</h3>
        <p>
          We strive to display accurate product information and pricing.
          However, we reserve the right to correct any errors, inaccuracies, or
          omissions and to change or update information at any time without
          prior notice.
        </p>

        <h3 className={styles.policySubTitle}>User Accounts</h3>
        <p>
          When you create an account, you are responsible for maintaining the
          confidentiality of your account information and for all activities
          that occur under your account.
        </p>
        <h3 className={styles.policySubTitle}>Intellectual Property</h3>
        <p>
          All content on BandhejHub, including text, graphics, logos, images,
          and software, is the property of BandhejHub and is protected by
          copyright and other intellectual property laws.
        </p>

        <h3 className={styles.policySubTitle}>Limitation of Liability</h3>
        <p>
          BandhejHub shall not be liable for any direct, indirect, incidental,
          special, or consequential damages resulting from the use or inability
          to use our products or services.
        </p>

        <h3 className={styles.policySubTitle}>Governing Law</h3>
        <p>
          These Terms of Service shall be governed by and construed in
          accordance with the laws of India, without regard to its conflict of
          law provisions.
        </p>
      </div>
      <div className={styles.bottomText}>
        <p>
          If you have any questions about our policies, please contact us at{" "}
          <a href="mailto:info@bandhejhub.tech">support@bandhejhub.tech</a>.
        </p>
        <div className={styles.returnHome}>
          <p onClick={() => navigate("/")}>Return to Home</p>
        </div>
      </div>
    </div>
  );
};

export default Policies;
