import axiosInstance from "../components/Api/AxiosInstance";

export const fetchWishlist = async () => {
  try {
    const response = await axiosInstance.get("/wishlist");
    return response.data.wishListData;
  } catch (error) {
    console.error("Fetch wishlist error:", error);
    throw new Error("Failed to fetch wishlist.");
  }
};

export const addToWishlist = async (sku) => {
  try {
    await axiosInstance.post("/wishlist", { sku });
  } catch (error) {
    console.error("Add to wishlist error:", error);
    throw new Error("Failed to add item to wishlist.");
  }
};

export const removeFromWishlist = async (sku) => {
  try {
    await axiosInstance.delete(`/wishlist/${sku}`);
  } catch (error) {
    console.error("Remove wishlist item error:", error);
    throw new Error("Failed to remove item from wishlist.");
  }
};
