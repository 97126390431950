import React, { useContext, useState } from "react";
import styles from "./Login.module.css";
import { RxCross1, BiShow, BiHide } from "../assets/Icons";
import { UserContext } from "../context/userContext";
import { USER_REGISTER } from "../components/Api/Apis";
import axios from "axios";
import { toast } from "react-toastify";

import Loader from "../assets/spinnerbandhej.svg";
const RegisterModal = ({ isOpen }) => {
  const { setIsLoginOpen, setIsRegisterOpen } = useContext(UserContext);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  if (!isOpen) return null;

  const CloseModal = () => {
    setIsRegisterOpen(false);
    document.body.style.overflow = "auto";
  };

  const OpenLogin = () => {
    setIsLoginOpen(true);
    setIsRegisterOpen(false);
  };

  const handleInput = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const SubmitForm = async (e) => {
    setButtonLoader(true);
    e.preventDefault();
    try {
      const response = await axios.post(USER_REGISTER, userData, {
        withCredentials: true,
      });
      localStorage.setItem("BandhejHub", response.data.accessToken);
      window.location.href = "/";
      setButtonLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setButtonLoader(false);
    }
    return true;
  };
  return (
    <>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <button onClick={CloseModal} className={styles.closeButton}>
            <RxCross1 />
          </button>
          <img
            src="/assets/bhlogo.png"
            alt="Bandhej Hub"
            className={styles.logo}
          />
          <form method="POST" onSubmit={SubmitForm}>
            {/* Name  */}
            <div className={styles.inputGroup}>
              <label htmlFor="Name">
                Your Name <span>*</span>{" "}
              </label>
              <input
                type="text"
                name="name"
                value={userData?.name}
                required
                onChange={handleInput}
              />
            </div>
            {/* Email  */}
            <div className={styles.inputGroup}>
              <label htmlFor="email">
                Email <span>*</span>{" "}
              </label>
              <input
                type="email"
                name="email"
                value={userData?.email}
                required
                onChange={handleInput}
              />
            </div>
            {/* phone  */}
            <div className={styles.inputGroup}>
              <label htmlFor="phone">
                Mobile number <span>*</span>{" "}
              </label>
              <input
                type="text"
                name="phone"
                value={userData?.phone}
                required
                onChange={handleInput}
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="password">
                Set password <span>*</span>
              </label>
              <input
                type={passwordVisible ? "text" : "password"}
                name="password"
                value={userData?.password}
                required
                onChange={handleInput}
              />
              <span
                className={styles.showPassword}
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                {passwordVisible ? <BiHide size={16} /> : <BiShow size={16} />}
              </span>
            </div>
            {buttonLoader ? (
              <img src={Loader} alt="Loading..." className={styles.loader} />
            ) : (
              <button className={styles.loginButton}>Register</button>
            )}
          </form>
          <p className={styles.registerbtn}>
            Already at Bandhej Hub?{" "}
            <span onClick={OpenLogin} style={{ cursor: "pointer" }}>
              Login
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default RegisterModal;
