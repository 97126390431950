import React, { useEffect, useState } from "react";
import styles from "./Collections.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllProducts } from "../../services/Product";
import Loader from "../../assets/spinnerwhite.svg";
const Collections = () => {
  const navigate = useNavigate();
  const [allProducts, setAllProducts] = useState(null);

  const collections = [
    {
      image:
        "https://wholetex.sgp1.cdn.digitaloceanspaces.com/full/m-n-kacchi-bandhej-satin-bandhani-saree-922.jpg",
      category: "Saree",
      product: allProducts?.categoryCounts["Saree"],
    },
    {
      image:
        "https://5.imimg.com/data5/YO/BQ/FD/ANDROID-49644284/product-jpeg-500x500.jpg",
      category: "Dupatta",
      product: allProducts?.categoryCounts["Dupatta"],
    },
    {
      image:
        "https://kalabandhej.com/wp-content/uploads/2024/04/Pure-Gaji-Silk-Bandhani-Dress-Gharchola-2.jpg",
      category: "Dress",
      product: allProducts?.categoryCounts["Dress"],
    },
    {
      image:
        "https://www.aishwaryadesignstudio.com/content/images/thumbs/0136900_traditional-dark-green-bandhani-designer-lehenga-choli-for-wedding.jpeg",
      category: "Lehangas",
      product: allProducts?.categoryCounts["Lehanga"] ?? 0,
    },
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getAllProducts();
        setAllProducts(products);
      } catch (errorMessage) {
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className={styles.ourCollections}>
      <h1> Our Collections</h1>
      <p className={styles.ourCollectionTagline}>
        Find the perfect outfit for every occasion.
      </p>
      <div className={styles.collections}>
        {collections.map((collection, index) => (
          <div
            key={index}
            className={styles.collection}
            onClick={() => navigate(`/${collection.category}?page=1`)}
          >
            <img
              src={collection.image}
              alt="img"
              sizes="(max-width: 480px) 480px, (max-width: 768px) 768px, 1200px"
              loading="lazy"
            />
            <div className={styles.collectionContent}>
              <h2>{collection.category}</h2>
              {loading ? (
                <img src={Loader} alt="Loading..." />
              ) : (
                <p>
                  {collection.product > 0
                    ? `${collection.product}+ Products`
                    : "No products available"}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Collections;
