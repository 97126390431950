import axiosInstance from "../components/Api/AxiosInstance";

export const addToCart = async (quantity, sku) => {
  try {
    const response = await axiosInstance.post("/cart", { quantity, sku });
    return response.data;
  } catch (error) {
    console.error("Add to cart error:", error);
    throw new Error(
      error.response?.data?.message || "Failed to add product to cart."
    );
  }
};

export const removeCartItem = async (sku) => {
  try {
    const response = await axiosInstance.delete(`/cart/${sku}`);
    return response.data;
  } catch (error) {
    console.error("Remove cart item error:", error);
    throw new Error(
      error.response?.data?.error || "Failed to remove product from cart."
    );
  }
};

export const getCartData = async () => {
  try {
    const response = await axiosInstance.get("/cart");
    return response.data.cartData;
  } catch (error) {
    console.error("Fetch cart data error:", error);
    throw new Error("Failed to fetch cart data.");
  }
};
