import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./ProductDetail.module.css";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import { FaWhatsapp, MdVerified } from "../../assets/Icons";
import CartIcon from "../../assets/cart.svg";
import HeartIcon from "../../assets/heart.svg";
import RedHeartIcon from "../../assets/red-heart.svg";

import Loader from "../../assets/spinnerwhite.svg";
import { UserContext } from "../../context/userContext";
import LoginModal from "../../Modals/Login";
import RegisterModal from "../../Modals/Register";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FullStarIcon from "../../assets/RatingFullStarIcon.svg";
import BlankStarIcon from "../../assets/RatingBlankStarIcon.svg";
import ShareIcon from "../../assets/shareIcon.svg";
import { getProductById, getVariantReviews } from "../../services/Product";
import { calculateAverageRating, formatTimeAgo } from "../../utils";
const ProductDetail = () => {
  const {
    handleAddToCart,
    cartLoader,
    isLoginOpen,
    isRegisterOpen,
    Commisions,
    wishListData,
    removedWishlistItem,
    AddToWishList,
  } = useContext(UserContext);

  const navigate = useNavigate();
  //  ITEM ID AND SKU VARIANT
  const { category, name, productId } = useParams();

  const [searchParams] = useSearchParams();
  const sku = searchParams.get("variant");
  // STATE WHICH HAVE WHOLE PRODUCT INFO
  const [productInfo, setProductInfo] = useState(null);
  if (productInfo) document.title = `${productInfo?.name} - Bandhej Hub`;
  // check product is wishlist or not
  const [isInWishlist, setIsInWishlist] = useState(
    wishListData?.some((item) => item.variant.sku === sku)
  );
  const [reviews, setReviews] = useState([]);
  const rating = useMemo(() => calculateAverageRating(reviews), [reviews]);

  // Update isInWishlist state when wishListData changes
  useEffect(() => {
    setIsInWishlist(wishListData?.some((item) => item.variant.sku === sku));
  }, [wishListData, sku]);
  // QUANTITY
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(true);
  // GET PRODUCT BY ID
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productData = await getProductById(productId);
        setProductInfo(productData);
      } catch (errorMessage) {
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  // get variant reviews
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await getVariantReviews(sku);
        setReviews(reviewsData);
      } catch (errorMessage) {
        console.error(errorMessage);
      }
    };
    fetchReviews();
  }, [sku]);

  // STATE OF STORE INDIVIDUAL PRODUCTINFO
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    const match = productInfo?.variants?.find((variant) => variant.sku === sku);
    setSelectedImage(match);
  }, [sku, productInfo]);

  // QUANTITY SET UP
  const handleQuantityChange = (e) => {
    const value = parseInt(e.target.value);
    setQuantity(value);
  };

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  //this state have {sku,image,color}
  const handleShowImg = (variant) => {
    navigate(
      `/${productInfo?.category}/${productInfo?.name.replace(/\s+/g, "-")}/${
        productInfo?.productId
      }?variant=${variant?.sku}`
    );
  };

  //navigation options for product review,description,detail
  const [option, setOption] = useState("productDetails");
  const handleOption = (option) => {
    setOption(option);
  };

  const UniqueNumber = (str) => {
    if (str) {
      let result = str.substring(4, 9);
      return result;
    }
  };
  const truncateText = (text) => {
    if (text) {
      if (text.length < 22) return text;
      return text.slice(0, 22) + "...";
    }
  };
  const handleWishlistClick = () => {
    setIsInWishlist(!isInWishlist);
    if (isInWishlist) {
      removedWishlistItem(sku);
    } else {
      AddToWishList(sku);
    }
  };

  // share logic
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this product!",
          text: "I found this amazing product, take a look!",
          url: window.location.href, // Current product page URL
        });
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Sharing not supported on this browser.");
    }
  };

  if (loading) {
    return (
      <div className={styles.productDetailsContainerrLoading}>
        <div className={styles.productImageContainerrLoading}>
          {window.innerWidth > 550 ? (
            <Skeleton height={500} width={500} />
          ) : (
            <Skeleton height={300} width={300} />
          )}
        </div>
        <div className={styles.productDetailssLoading}>
          {window.innerWidth > 550 ? (
            <>
              <Skeleton height={30} width={400} />
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={170} />
              <Skeleton height={20} width={100} />
              <Skeleton height={25} width={400} />
              <Skeleton height={25} width={400} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
            </>
          ) : (
            <>
              <Skeleton height={30} width={250} />
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={170} />
              <Skeleton height={20} width={100} />
              <Skeleton height={25} width={300} />
              <Skeleton height={25} width={300} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
              <Skeleton height={20} width={200} />
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.productDetailsTopContainer}>
        {/* navigation link  */}
        <div className={styles.productDetailsTopContainerNavigation}>
          <div>
            <Link to="/">Home</Link>
          </div>
          <div>
            <MdOutlineKeyboardArrowRight />
          </div>
          <div>
            <Link to={`/${productInfo?.category}`}>
              {productInfo?.category}
            </Link>
          </div>
          <div>
            <MdOutlineKeyboardArrowRight />
          </div>
          <div>{truncateText(productInfo?.name)}</div>
        </div>
        {/* images and right details section  */}
        <div className={styles.productDetailsContainerr}>
          <div className={styles.productImageContainerr}>
            <div className={styles.productImageContainerrMoreColors}>
              {productInfo?.variants?.map((varient, index) => (
                <div key={varient?.sku} onClick={() => handleShowImg(varient)}>
                  <img
                    className={
                      varient?.image === selectedImage?.image
                        ? styles.activeImg
                        : ""
                    }
                    src={varient?.image}
                    alt={productInfo?.name}
                  />
                </div>
              ))}
            </div>

            <div className={styles.productImageContainerrTopShowImg}>
              <img
                src={selectedImage?.image}
                alt={productInfo?.name}
                className={!selectedImage?.stock ? styles.TopImageOutStock : ""}
              />
              {!selectedImage?.stock && (
                <div className={styles.outOfStockStrip}>Out of Stock</div>
              )}
            </div>
          </div>

          <div className={styles.productDetailss}>
            <div>
              {/* stock and sku  */}
              <div className={styles.productStock}>
                <div>
                  {selectedImage?.stock ? (
                    <p className={styles.productDetailsInStock}>In Stock</p>
                  ) : (
                    <p className={styles.productDetailsOutStock}>
                      Out of stock
                    </p>
                  )}
                </div>
                <div>SKU : {UniqueNumber(selectedImage?.sku)}</div>
              </div>
              {/* product heading   */}
              <h2>{productInfo?.name}</h2>
              {/* rating  */}
              <div className={styles.rating}>
                <div className={styles.ratingStar}>
                  {[...Array(5)].map((_, index) => (
                    <img
                      key={index}
                      src={index < rating ? FullStarIcon : BlankStarIcon}
                      alt="star"
                    />
                  ))}
                </div>

                <div>
                  <p>({reviews.length} reviews)</p>
                </div>
              </div>

              {/* price  */}
              <p className={styles.productDetailsPrice}>
                {/* price */}₹{Commisions(productInfo?.showPrice)}.00
                {/* showprice  */}
                {productInfo?.showPrice !== productInfo?.price && (
                  <span>₹{productInfo?.price}.00</span>
                )}
                {/* discount  */}
                {productInfo?.discount && (
                  <span>{productInfo?.discount}% OFF</span>
                )}
              </p>

              <div className={styles.includingTax}>(Including all taxes)</div>
              <div className={styles.horiZontalLine}></div>
              {/* quantity and cart button  */}
              <div className={styles.quantityUpdate}>
                <p>Quantity</p>
                <div className={styles.quantityControl}>
                  <button onClick={decreaseQuantity}>-</button>
                  <input
                    type="number"
                    min="1"
                    // disabled
                    readOnly
                    value={quantity}
                    onChange={handleQuantityChange}
                  />
                  <button onClick={increaseQuantity}>+</button>
                </div>
              </div>

              <div className={styles.quantityContainer}>
                {cartLoader ? (
                  <button className={styles.purchaseButtonLoader} disabled>
                    {/* Loading... */}
                    <img src={Loader} alt="" />
                  </button>
                ) : (
                  <button
                    className={styles.purchaseButton}
                    onClick={() =>
                      handleAddToCart(quantity, selectedImage?.sku)
                    }
                  >
                    <img src={CartIcon} alt="Add to Cart" />
                    <div>Add Cart</div>
                  </button>
                )}
                {isInWishlist ? (
                  <div
                    className={styles.heartButton}
                    onClick={handleWishlistClick}
                  >
                    <img src={RedHeartIcon} alt="" />
                    <p>Remove Wishlist</p>
                  </div>
                ) : (
                  <div
                    className={styles.heartButton}
                    onClick={handleWishlistClick}
                  >
                    <img src={HeartIcon} alt="" />
                    <p>Add To Wishlist</p>
                  </div>
                )}
              </div>
              {/* whatsapp button  */}
              <div className={styles.whatsappOrderButton}>
                <a
                  href={`https://wa.me/7740930250?text=https://www.bandhejhub.tech/${category}/${name}/${productId}?variant=${sku}%0A%0AI%20want%20to%20buy%20it😍`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i>Order via Whatsapp</i>
                  <FaWhatsapp />
                </a>
                <div className={styles.shareButton} onClick={handleShare}>
                  <img src={ShareIcon} alt="share" />
                </div>
              </div>
            </div>
            <div className={styles.sellerInfo}>
              <i>
                Sold By : {productInfo?.seller?.shopName}
                <span>
                  <MdVerified />{" "}
                </span>
              </i>
            </div>
          </div>
        </div>
        {/* bottom optioms  */}
        <div className={styles.singleProductOptions}>
          <p
            className={option === "productDetails" ? styles.active : ""}
            onClick={() => handleOption("productDetails")}
          >
            Product Details
          </p>
          <p
            className={option === "description" ? styles.active : ""}
            onClick={() => handleOption("description")}
          >
            Description
          </p>
          <p
            className={option === "reviews" ? styles.active : ""}
            onClick={() => handleOption("reviews")}
          >
            Reviews
          </p>
          <p
            className={option === "returnPolicy" ? styles.active : ""}
            onClick={() => handleOption("returnPolicy")}
          >
            {" "}
            Return Policy
          </p>
        </div>
        {option === "productDetails" && (
          <div className={styles.container}>
            {/* fabric  */}
            <div className={styles.row}>
              <span className={styles.label}>fabric</span>
              <span className={styles.value}>{productInfo?.fabric}</span>
            </div>
            {/* pattern  */}
            {productInfo?.pattern && (
              <div className={styles.row}>
                <span className={styles.label}>pattern</span>
                <span className={styles.value}>{productInfo?.pattern}</span>
              </div>
            )}
            {/* dimension  */}
            <div className={styles.row}>
              <span className={styles.label}>dimension</span>
              <span className={styles.value}>{productInfo?.dimension}m</span>
            </div>
            {/* blouse  */}
            {productInfo?.blouse && (
              <div className={styles.row}>
                <span className={styles.label}>blouse</span>
                <span className={styles.value}>{productInfo?.blouse}</span>
              </div>
            )}
            {/* wight  */}
            <div className={styles.row}>
              <span className={styles.label}>wight</span>
              <span className={styles.value}>{productInfo?.weight} gm</span>
            </div>
            {/* size  */}
            <div className={styles.row}>
              <span className={styles.label}>size</span>
              <span className={styles.value}>{productInfo?.size}</span>
            </div>
            {/* careGuide  */}
            {productInfo?.careGuide && (
              <div className={styles.row}>
                <span className={styles.label}>care guide</span>
                <span className={styles.value}>{productInfo?.careGuide}</span>
              </div>
            )}
          </div>
        )}
        {option === "description" && (
          <>
            {productInfo?.description && (
              <p className={styles.productDetailsDesc}>
                {productInfo?.description}
              </p>
            )}
            {!productInfo?.description && (
              <p className={styles.productDetailsDesc}>
                No description available for this product!
              </p>
            )}
          </>
        )}
        {option === "reviews" && (
          <>
            {reviews.length > 0 ? (
              <div className={styles.productReview}>
                {reviews.map((review) => {
                  return (
                    <div className={styles.reviewCard} key={review.reviewId}>
                      <div className={styles.reviewProfile}>
                        <div className={styles.reviewAvatar}></div>
                        <div>
                          <h4 className={styles.reviewName}>
                            {review.user.name ||
                              review.user.email.split("@")[0]}
                          </h4>{" "}
                          <div className={styles.reviewRating}>
                            <div>
                              {[...Array(5)].map((_, index) => (
                                <img
                                  key={index}
                                  src={
                                    index < review.rating
                                      ? FullStarIcon
                                      : BlankStarIcon
                                  }
                                  alt="rating"
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className={styles.reviewComment}>{review.comment}</p>
                      <span className={styles.reviewTime}>
                        {formatTimeAgo(review.createdAt)}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className={styles.productDetailsDesc}>
                There are no reviews yet for this product.
              </p>
            )}
          </>
        )}
        {option === "returnPolicy" && (
          <p className={styles.productDetailsDesc}>
            Check our return policy <Link to="/Policies">here</Link>
          </p>
        )}
      </div>
      <LoginModal isOpen={isLoginOpen} />
      <RegisterModal isOpen={isRegisterOpen} />
    </>
  );
};

export default ProductDetail;
