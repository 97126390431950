export const calculateAverageRating = (reviews) => {
  if (!reviews || reviews.length === 0) return 0;

  const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
  return Math.round(totalRating / reviews.length);
};

export const truncateText = (text) => {
  if (window.innerWidth <= 775) {
    if (text.length < 20) return text;
    return text.slice(0, 20) + "...";
  } else {
    if (text.length < 25) return text;
    return text.slice(0, 25) + "...";
  }
};

export const formatTimeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "week", seconds: 604800 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds);
    if (count > 0) {
      return `${count} ${interval.label}${count !== 1 ? "s" : ""} ago`;
    }
  }

  return "Just now";
};

export const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  return dateObj.toLocaleString("en-IN", {
    timeZone: "Asia/Kolkata", // Set to IST (Indian Standard Time)
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};
export const truncateOrderId = (orderId) => {
  if (orderId) {
    const n = orderId.length;
    return orderId.slice(n - 8, n);
  }
};
