import React, { useCallback, useContext, useEffect, useState } from "react";
import styles from "./Checkout.module.css";
import { PAYMENTVERIFY } from "../Api/Apis";
import { UserContext } from "../../context/userContext";
import Loader from "../../assets/spinnerwhite.svg";
import { PromoCodeApply } from "../../services/PromoCode";
import { processPayment } from "../../services/Payment";
import { Link } from "react-router-dom";
const Checkout = () => {
  const { cartData, Commisions, profile } = useContext(UserContext);
  const queryParams = new URLSearchParams(window.location.search);
  const promocode = queryParams.get("promocode");
  const [buttonLoader, setButtonLoader] = useState(false);
  const orderItems = () => {
    if (!cartData || !Array.isArray(cartData)) {
      console.error("cartData is not defined or not an array");
      return [];
    }
    return cartData.map((item) => ({
      sellerId: item?.variant?.product?.sellerId,
      quantity: item?.quantity,
      sku: item?.sku,
    }));
  };
  const [discount, setDiscount] = useState(0);

  const [prices, setPrices] = useState({
    subtotal: 0,
    total: 0,
  });

  const [shippingDetails, setshippingDetails] = useState({
    // country: "india",
    name: "",
    phone: "",
    email: profile?.email || "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
  });
  console.log("🚀 ~ Checkout ~ shippingDetails:", shippingDetails);
  const handleInput = (e) => {
    const { name, value } = e.target;

    // Validate PIN code length
    if (name === "pinCode" && value.length > 6) {
      return;
    }
    // Validate Phone Number length
    if (name === "phone" && value.length > 10) {
      return;
    }

    setshippingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const [billingDetails, setBillingDetails] = useState({
    // country: "india",
    name: "",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    pinCode: "",
  });

  const handleInputBilling = (e) => {
    const { name, value } = e.target;

    // Validate PIN code length
    if (name === "pinCode" && value.length > 6) {
      return;
    }
    // Validate Phone Number length
    if (name === "phone" && value.length > 10) {
      return;
    }
    setBillingDetails({
      ...billingDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);

    const order = {
      shippingDetails,
      billingDetails,
      orderItems: orderItems(),
    };

    const requestBody = {
      payment: profile?.is_first_purchase
        ? Math.round(prices?.total - prices?.subtotal * 0.05)
        : Math.round(prices?.total),
      receipt: "sfojwoejfo30",
      order,
      shppingbilling: redioOption === "same",
      promoCode: prices?.subtotal === prices?.total ? "" : promocode,
    };

    try {
      const paymentData = await processPayment(requestBody);

      const options = {
        key: process.env.REACT_APP_PAYMENT_GATEWAY_KEY_ID,
        amount: paymentData.amount,
        currency: "INR",
        name: "Bandhej Hub",
        description: "Secure transaction with Bandhej Hub",
        image: "/assets/bhlogo.png",
        order_id: paymentData.id,
        callback_url: PAYMENTVERIFY,
        prefill: {
          name: shippingDetails.name,
          email: shippingDetails.email,
          contact: shippingDetails.phone,
        },
        notes: { address: shippingDetails.address },
        theme: { color: "#1c5560" },
      };

      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error("Payment Error:", error.message);
    } finally {
      setButtonLoader(false); // Ensures the loader stops in both success and error cases
    }
  };

  useEffect(() => {
    if (cartData && cartData?.length > 0) {
      const subtotal = cartData.reduce(
        (acc, item) =>
          acc + Commisions(item?.variant?.product.showPrice) * item.quantity,
        0
      );

      setPrices((prevPrices) => ({
        ...prevPrices,
        subtotal: Math.max(subtotal, 0),
      }));
    }
  }, [cartData]);

  const [redioOption, setRadioOption] = useState("same");
  const generateDiscount = useCallback(async () => {
    if (!promocode) {
      setPrices((prevPrices) => ({
        ...prevPrices,
        total: Math.max(prices.subtotal, 0),
      }));
      return;
    }

    try {
      const newDiscount = await PromoCodeApply(promocode, prices?.subtotal);
      setDiscount(newDiscount);

      setPrices((prevPrices) => ({
        ...prevPrices,
        total: Math.max(prices.subtotal - newDiscount, 0),
      }));
    } catch (error) {
      console.error(error);
    }
  }, [promocode, prices?.subtotal]);

  useEffect(() => {
    generateDiscount();
  }, [generateDiscount]); // Only include generateDiscount here

  return (
    <div className={styles.checkoutSection}>
      <div className={styles.checkoutDeliverySection}>
        <form method="POST" onSubmit={handleSubmit}>
          {/* delivery  */}
          <div className={styles.deliveryDetails}>
            <h4>Delivery Details</h4>
            <div>
              <input type="text" required value="India" disabled />
            </div>

            <div>
              <input
                type="text"
                required
                placeholder="Full Name"
                name="name"
                value={shippingDetails.name}
                onChange={handleInput}
              />
            </div>

            <div>
              <input
                type="text"
                required
                placeholder="Address"
                name="address"
                value={shippingDetails.address}
                onChange={handleInput}
              />
            </div>

            <div className={styles.checkoutDeliverySectionLandmarkInput}>
              <input
                type="text"
                required
                placeholder="City"
                name="city"
                value={shippingDetails.city}
                onChange={handleInput}
              />
              <input
                style={{ margin: "0 10px" }}
                type="text"
                required
                placeholder="State"
                name="state"
                value={shippingDetails.state}
                onChange={handleInput}
              />
              <input
                type="number"
                maxLength={6}
                required
                placeholder="PIN Code"
                name="pinCode"
                value={shippingDetails.pinCode}
                onChange={handleInput}
              />
            </div>
            <div>
              <input
                type="email"
                required
                placeholder="Email Address"
                name="email"
                value={profile?.email}
                onChange={handleInput}
                disabled
              />
            </div>
            <div>
              <input
                type="text"
                required
                placeholder="Phone Number"
                name="phone"
                value={shippingDetails.phone}
                onChange={handleInput}
              />
            </div>
          </div>
          {/* billing option  */}
          <div className={styles.billingtop}>
            <h4>Billing address</h4>
            <div className={styles.billingAddressSection}>
              <div className={styles.billingOption}>
                <label className={styles.billingOptionLabel}>
                  <input
                    type="radio"
                    name="billingAddress"
                    value="same"
                    defaultChecked
                    onChange={(e) => setRadioOption(e.target.value)}
                  />
                  Same as shipping address
                </label>
              </div>
              <div className={styles.billingOption}>
                <label className={styles.billingOptionLabel}>
                  <input
                    type="radio"
                    name="billingAddress"
                    value="different"
                    onChange={(e) => setRadioOption(e.target.value)}
                  />
                  Use a different billing address
                </label>
              </div>
            </div>
          </div>
          {/* billing address  */}
          {redioOption === "different" && (
            <div className={styles.deliveryDetails}>
              <div>
                <input type="text" required value="India" disabled />
              </div>

              <div>
                <input
                  type="text"
                  required
                  placeholder="Full Name"
                  name="name"
                  value={billingDetails.name}
                  onChange={handleInputBilling}
                />
              </div>

              <div>
                <input
                  type="text"
                  required
                  placeholder="Address"
                  name="address"
                  value={billingDetails.address}
                  onChange={handleInputBilling}
                />
              </div>

              <div className={styles.checkoutDeliverySectionLandmarkInput}>
                <input
                  type="text"
                  required
                  placeholder="City"
                  name="city"
                  value={billingDetails.city}
                  onChange={handleInputBilling}
                />
                <input
                  style={{ margin: "0 10px" }}
                  type="text"
                  required
                  placeholder="State"
                  name="state"
                  value={billingDetails.state}
                  onChange={handleInputBilling}
                />
                <input
                  type="number"
                  required
                  placeholder="PIN code"
                  name="pinCode"
                  value={billingDetails.pinCode}
                  onChange={handleInputBilling}
                />
              </div>

              <div>
                <input
                  type="email"
                  required
                  placeholder="Email Address"
                  name="email"
                  value={billingDetails.email}
                  onChange={handleInputBilling}
                />
              </div>
              <div>
                <input
                  type="text"
                  required
                  placeholder="Phone Number"
                  name="phone"
                  value={billingDetails.phone}
                  onChange={handleInputBilling}
                />
              </div>
            </div>
          )}

          {/* submit button  */}
          {buttonLoader ? (
            <button className={styles.purchaseButtonLoader} disabled>
              {/* Loading... */}
              <img src={Loader} alt="" />
            </button>
          ) : (
            <div className={styles.checkoutDeliverySectionSubmitInput}>
              <input type="submit" value="Pay now" />
            </div>
          )}
        </form>
        {/* services  */}
        <div className={styles.services}>
          <p>
            {" "}
            <Link to="/Policies">Shipping Policy</Link>
          </p>
          <p>
            {" "}
            <Link to="/Policies">Return & Refund Policy</Link>
          </p>
          <p>
            {" "}
            <Link to="/Policies">Terms of Service</Link>
          </p>
        </div>
      </div>

      <div className={styles.checkoutCartSection}>
        <div>
          <table>
            <tbody>
              {cartData?.map((item) => (
                <tr className={styles.checkoutCartContent} key={item?.sku}>
                  <td>
                    <div className={styles.checkoutCartImagesDetail}>
                      <div>
                        <img src={item?.variant?.image} alt="Loading..." />
                        <span>{item?.quantity}</span>
                      </div>
                      <div className={styles.checkoutCartDetail}>
                        <p>{item?.variant?.product?.name}</p>
                        <p>Color : {item?.variant?.color}</p>
                        <p>
                          Rs. {Commisions(item?.variant?.product?.showPrice)}.00
                        </p>
                      </div>
                    </div>
                  </td>

                  <td>
                    Rs.
                    {Commisions(item?.variant?.product?.showPrice) *
                      item?.quantity}
                    .00
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <hr />
        {/* amount  */}
        <div className={styles.cartAmount}>
          <div className={styles.cartAmountRupee}>
            <h3>Subtotal</h3>
            <h3>Rs.{prices?.subtotal}</h3>
          </div>
          {profile?.is_first_purchase && (
            <div className={`${styles.cartAmountRupee} ${styles.highlight}`}>
              <h3>First Purchase(5%)</h3>
              <h3> -Rs.{(prices?.subtotal * 0.05).toFixed(0)}</h3>
            </div>
          )}
          {/* promocodsubtotale  */}
          <div className={`${styles.cartAmountRupee} ${styles.highlight}`}>
            <h3>Discount</h3>
            <h3>-Rs.{discount.toFixed(0)}</h3>
          </div>
          {/* delivery  */}
          <div className={styles.cartAmountRupee}>
            <h3>Delivery</h3>
            <h3>Rs.0</h3>
          </div>
          {/* total  */}

          {profile &&
            (profile?.is_first_purchase ? (
              <div className={styles.cartAmountRupee}>
                <h3>Total</h3>
                <h3>
                  Rs.{Math.round(prices?.total - prices?.subtotal * 0.05)}
                </h3>
              </div>
            ) : (
              <div className={styles.cartAmountRupee}>
                <h3>Total</h3>
                <h3>Rs.{Math.round(prices?.total)}</h3>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Checkout;
