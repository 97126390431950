import React, { useEffect, useRef } from "react";
import styles from "./TrackingAvlModal.module.css";

function TrackOrderModal({
  showTrackingModal,
  setShowTrackingModal,
  trackingId,
  trackingSite,
}) {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowTrackingModal(false);
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (showTrackingModal === false) return null; // Don't render if not visible

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} ref={modalRef}>
        <button
          className={styles.closeButton}
          onClick={() => setShowTrackingModal(false)}
        >
          &times;
        </button>
        <h2>Track Your Order</h2>
        <div className={styles.modalField}>
          <label>Tracking ID</label>
          <input type="text" value={trackingId} readOnly />
        </div>
        <div className={styles.modalField}>
          <label>Tracking Link</label>
          <div className={styles.trackingLink}>
            <span>{trackingSite}</span>
            <a
              href={`${trackingSite}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="16"
                height="16"
              >
                <path d="M14 3h7v7h-2V6.414l-7.293 7.293-1.414-1.414L17.586 5H14V3z" />
                <path d="M5 5h4V3H5C3.897 3 3 3.897 3 5v14c0 1.103 0.897 2 2 2h14c1.103 0 2-0.897 2-2v-4h-2v4H5V5z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackOrderModal;
