import React, { useState, useEffect, useRef } from "react";
import styles from "./ReviewModal.module.css";
import BlankStarIcon from "../../../assets/RatingBlankStarIcon.svg";
import FullStarIcon from "../../../assets/RatingFullStarIcon.svg";
import Loader from "../../../assets/spinnerwhite.svg";
import { toast } from "react-toastify";
import axiosInstance from "../../Api/AxiosInstance";
import { submitReview } from "../../../services/Order";

const ReviewModal = ({
  reviewModal,
  setReviewModal,
  orderId,
  productName,
  sku,
  setOrderReviewed,
}) => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setReviewModal(false);
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!reviewModal) return null; // Don't render if not visible

  const handleReview = async () => {
    setLoading(true);
    try {
      await submitReview({ orderId, sku, rating, comment });
      toast.success("Thanks For Submitting Review");
      setReviewModal(false);
      setOrderReviewed(true);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modal} ref={modalRef}>
        <button
          className={styles.closeButton}
          onClick={() => setReviewModal(false)}
        >
          ×
        </button>
        <h2 className={styles.title}>Write a Review </h2>
        <p className={styles.subtitle}>Share your thoughts on {productName}</p>

        <div className={styles.formGroup}>
          <label>Rating</label>
          <div className={styles.rating}>
            {[1, 2, 3, 4, 5].map((star) => (
              <img
                key={star}
                src={rating >= star ? FullStarIcon : BlankStarIcon}
                alt="star"
                onClick={() => setRating(star)}
                style={{ cursor: "pointer" }}
              />
            ))}
          </div>
        </div>
        <div className={styles.formGroup}>
          <label>Your Review</label>
          <textarea
            className={styles.textarea}
            placeholder="What did you like or dislike? How was your experience using this product?"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>

        {comment === "" || rating === 0 ? (
          <div className={`${styles.buttonGroup} ${styles.disabledButton}`}>
            <button>Submit Review</button>
          </div>
        ) : (
          <div className={styles.buttonGroup} onClick={handleReview}>
            {loading ? (
              <button>
                <img src={Loader} alt="" />
              </button>
            ) : (
              <button>Submit Revuew</button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewModal;
