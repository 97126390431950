import React, { useContext, useEffect, useState, useRef } from "react";
import styles from "./OrderDetail.module.css";
import { Link, useParams } from "react-router-dom";
import { GiConfirmed } from "react-icons/gi";
import { MdOutlineLocalShipping } from "react-icons/md";
import { UserContext } from "../../context/userContext";
// assets
import ScreenLoader from "../../assets/spinnerbandhej.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import ToolTipIconRed from "../../assets/tooltipIconRed.svg";
import ToolTipBlack from "../../assets/tooltipIcon.svg";
import { FiArrowLeft } from "react-icons/fi";
import { FaBox, FaClipboardCheck } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
// modals
import TrackingNotAvailableModal from "./TrackingNotModal/TrackingNotModal";
import TrackingAvlModal from "./TrackingAvlModal/TrackingAvlModal";
import CancelOrderModal from "./CancelOrderModal/CancelOrderModal";
import ContactSupportModal from "./ContactSupportModal/ContactSupportModal";
import deliverSuccessIcon from "../../assets/deliveredSuccessIcon.svg";
import blackStarBlank from "../../assets/blackStarBlank.svg";
import ReviewModal from "./ReviewModal/ReviewModal";
import { getOrderById } from "../../services/Order";
import { toast } from "react-toastify";
import { PromoCodeApply } from "../../services/PromoCode";
import { formatDate, truncateOrderId } from "../../utils";
 const OrderDetails = () => {
  const { Commisions } = useContext(UserContext);
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [discount, setDiscount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [showOrderCancelModal, setShowOrderCancelModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [reviewModal, setReviewModal] = useState(false);
  const modalRef = useRef(null);
  const [orderHasBeenCancel, setOrderHasBeenCancel] = useState(false);
  const [orderReviewed, setOrderReviewed] = useState(false);
  // get order by id
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderData = await getOrderById(orderId);
        setOrder(orderData);
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrder();
  }, [orderHasBeenCancel, orderReviewed]);

  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    { label: "Placed", icon: <FaBox /> },
    { label: "Confirm", icon: <GiConfirmed /> },
    { label: "Dispatched", icon: <MdOutlineLocalShipping /> },
    { label: "Delivered", icon: <FaClipboardCheck /> },
  ];

  useEffect(() => {
    if (order?.status === "Placed") setCurrentStep(0);
    else if (order?.status === "Confirm") setCurrentStep(1);
    else if (order?.status === "Dispatched") setCurrentStep(2);
    else setCurrentStep(3);
  }, [currentStep, order?.status]);

  useEffect(() => {
    if (order?.order?.promoCode) {
      submitPromoCode();
    }
  }, [order?.order?.promoCode]);

  const submitPromoCode = async () => {
    const totalAmount =
      Commisions(order?.variant?.product?.showPrice) * order?.quantity;
    const promoCode = order?.order?.promoCode;

    try {
      const response = await PromoCodeApply(promoCode, totalAmount);
      setDiscount(response);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!order) {
    return (
      <div className="screenLoader">
        <div>
          <img src={ScreenLoader} alt="loader..." />
        </div>
        <div>
          <span>Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.OrderDetails}>
      {/* top bar for order and buttons  */}
      <div className={styles.orderDetailTop}>
        {/* left order id  */}
        <div className={styles.orderDetailsTopFirst}>
          <div className={styles.orderDetailsTopFirstLeft}>
            <Link to="/myorders?status=All">
              <FiArrowLeft />
            </Link>
          </div>
          <div>
            <div>Order #{truncateOrderId(order?.orderItemId)}</div>
            <div> Placed on : {formatDate(order?.order.createdAt)}</div>
          </div>
        </div>
        {/* right button  */}
        <div className={styles.orderDetailsTopSecond}>
          <div>
            <button
              onClick={() => setIsOpen(true)}
              className={styles.threeDotsButton}
            >
              <BsThreeDotsVertical />
            </button>
            {isOpen && (
              <div className={styles.dropdownMenu} ref={modalRef}>
                <button
                  className={styles.dropdownItem}
                  onClick={() => {
                    setShowTrackingModal(true);
                    document.body.style.overflow = "hidden";
                  }}
                >
                  Track Order
                </button>
                {(order.status === "Placed" || order.status === "Confirm") && (
                  <button
                    className={styles.dropdownItem}
                    onClick={() => {
                      setShowOrderCancelModal(true);
                      document.body.style.overflow = "hidden";
                    }}
                  >
                    Cancel Order
                  </button>
                )}
                <button
                  className={styles.dropdownItem}
                  onClick={() => {
                    setShowContactModal(true);
                    document.body.style.overflow = "hidden";
                  }}
                >
                  Contact Support
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* if order cancel then one extra section  */}
      {order?.status === "Cancelled" && (
        <div className={styles.orderTopCancelSection}>
          <div>
            <img src={ToolTipIconRed} alt="tooltip" />
          </div>
          <div className={styles.orderTopCancelSectionText}>
            {order.is_canceled_by_user ? (
              <h5>Order Cancelled</h5>
            ) : (
              <h5>Order Cancelled by Seller</h5>
            )}
            <p>
              This order was cancelled on {formatDate(order?.cancel_date)}.
              Reason:{" "}
              {order?.canceled_reason
                .replace(/_/g, " ")
                .replace(/\b\w/g, (char) => char.toUpperCase())}
            </p>
          </div>
        </div>
      )}
      {/* order delivered  */}
      {order?.status === "Delivered" && (
        <div className={styles.orderTopRefundSection}>
          <div>
            <img src={deliverSuccessIcon} alt="tooltip" />
          </div>
          <div className={styles.orderTopRefundSectionText}>
            <h5>Order Delivered</h5>
            <p>
              Your order was successfully delivered on{" "}
              {formatDate(order?.delivered_date)}.
            </p>
            {!order?.review_done && (
              <div
                className={styles.orderDeliveredReviewButton}
                onClick={() => setReviewModal(true)}
              >
                <button>
                  <img src={blackStarBlank} alt="review" />
                  Write a Review
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* if order cancel then refund section */}
      {order.status === "Cancelled" && !order?.refund_status && (
        <div className={styles.orderTopRefundSection}>
          <div>
            <img src={ToolTipBlack} alt="tooltip" />
          </div>
          <div className={styles.orderTopRefundSectionText}>
            <h5>Refund Status</h5>
            <p>
              Your refund of Rs.{" "}
              {(
                Commisions(order?.variant?.product?.showPrice) *
                  order?.quantity -
                discount
              ).toFixed(0)}
              .00 has been initiated and will be processed within 5-7 business
              days.
            </p>
          </div>
        </div>
      )}
      {/* //prgress  */}
      <div className={styles.orderProgress}>
        <div className={styles.progressTracker}>
          <div className={styles.progressBarEstimate}>
            {order?.status === "Cancelled" ? (
              <div className={styles.steps}>
                {/* Placed */}
                <div className={styles.step}>
                  <div className={styles.icon}>
                    <FaBox />
                  </div>
                  <p className={styles.label}>Placed</p>
                </div>
                {/* Cancelled */}
                <div className={styles.step}>
                  <div className={styles.icon}>
                    <FcCancel />
                  </div>
                  <p className={styles.label}>Cancelled</p>
                </div>
              </div>
            ) : (
              <div className={styles.steps}>
                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`${styles.step} ${
                      currentStep >= index ? styles.active : ""
                    }`}
                  >
                    <div className={styles.icon}>{step.icon}</div>
                    <p className={styles.label}>{step.label}</p>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={styles.progressBar}>
            <div
              className={`${styles.progress} ${
                order.status === "Cancelled" ? styles.cancelledProgress : ""
              }`}
              style={{
                width: `${
                  currentStep === 0
                    ? 25
                    : currentStep === 1
                    ? 50
                    : currentStep === 2
                    ? 75
                    : 100
                }%`,
              }}
            ></div>
          </div>
          {order?.status === "Cancelled" ? (
            <div className={styles.info}>
              Need help with your cancelled order?{" "}
              <span
                className={styles.link}
                onClick={() => {
                  setShowContactModal(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                Contact support
              </span>
            </div>
          ) : (
            <div className={styles.info}>
              Haven't received your delivery?{" "}
              <span
                className={styles.link}
                onClick={() => {
                  setShowContactModal(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                Let us know
              </span>
            </div>
          )}
        </div>
      </div>

      <div className={styles.orderDetailsSecond}>
        {/* order details  */}

        <div className={styles.orderDetailSection}>
          <h3>Order Details</h3>
          <div className={styles.orderDetailsSecondFirst}>
            <div className={styles.orderDetailsMyorder}>
              {/* contact info  */}
              <div className={styles.orderDetailsContact}>
                <p>Contact Information</p>
                <p>+91-{order?.order?.shippingDetail?.phone}</p>
                <p>{order?.order?.shippingDetail?.email}</p>
              </div>
              {/* Name  */}

              {/* Shipping adredd  */}
              <div className={styles.orderDetailsContact}>
                <p>Shipping Address</p>
                <p>{order?.order?.shippingDetail.name}</p>
                <p>{order?.order?.shippingDetail.address}</p>
                <p>
                  {order?.order?.shippingDetail.city} -{" "}
                  {order?.order?.shippingDetail?.pinCode}
                </p>
                <p>{order?.order?.shippingDetail.state} - India</p>
              </div>
            </div>

            <div className={styles.orderDetailsMyorder}>
              {/* billing address  */}
              <div className={styles.orderDetailsContact}>
                <p>Billing Address</p>
                {order?.order?.billingDetail.address === "" ? (
                  <>
                    <p>{order?.order?.shippingDetail.name}</p>
                    <p>{order?.order?.shippingDetail.address}</p>
                    <p>
                      {order?.order?.shippingDetail.city} -{" "}
                      {order?.order?.shippingDetail?.pinCode}
                    </p>
                    <p>{order?.order?.shippingDetail.state} - India</p>
                  </>
                ) : (
                  <>
                    <p>{order?.order?.billingDetail.name}</p>
                    <p>{order?.order?.billingDetail.address}</p>
                    <p>
                      {order?.order?.billingDetail.city} -{" "}
                      {order?.order?.billingDetail?.pinCode}
                    </p>
                    <p>{order?.order?.billingDetail.state} - India</p>
                  </>
                )}
              </div>

              <div className={styles.orderDetailsContact}>
                <p>Seller</p>
                <p>{order?.seller.shopName}</p>
              </div>
            </div>
          </div>
        </div>

        {/* order summary  */}
        <div className={styles.orderDetailsSecondSecond}>
          <h3>Order Summary</h3>
          <div className={styles.checkoutCartSection}>
            <div>
              <table>
                <tbody>
                  <tr className={styles.checkoutCartContent}>
                    <td>
                      <div className={styles.checkoutCartImagesDetail}>
                        <div>
                          <img src={order?.variant?.image} alt="Loading..." />
                          <span>{order?.quantity}</span>
                        </div>
                        <div className={styles.checkoutCartDetail}>
                          <p>{order?.variant?.product?.name}</p>
                          <p>Color : {order?.variant?.color}</p>
                          <p>
                            Rs.{Commisions(order?.variant?.product?.showPrice)}
                            .00
                          </p>
                        </div>
                      </div>
                    </td>

                    <td>
                      Rs.
                      {Commisions(order?.variant?.product?.showPrice) *
                        order?.quantity}
                      .00
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />

            {/* amount  */}
            <div className={styles.cartAmount}>
              <div className={styles.cartAmountRupee}>
                <h3>Subtotal</h3>
                <h3>
                  Rs.
                  {Commisions(order?.variant?.product?.showPrice) *
                    order?.quantity}
                  .00
                </h3>
              </div>
              {/* promocodsubtotale  */}
              <div className={`${styles.cartAmountRupee} ${styles.highlight}`}>
                <h3>Discount</h3>
                <h3>-Rs.{discount.toFixed(0)}.00</h3>
              </div>
              {/* delivery  */}
              <div className={styles.cartAmountRupee}>
                <h3>Delivery</h3>
                <h3>Rs.0</h3>
              </div>
              {/* total  */}
              <div className={styles.cartAmountRupee}>
                <h3>Total</h3>
                <h3>
                  Rs.
                  {(
                    Commisions(order?.variant?.product?.showPrice) *
                      order?.quantity -
                    discount
                  ).toFixed(0)}
                  .00
                </h3>

                {/* <h3>Rs.{Math.round(prices?.total)}</h3> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {["Placed", "Confirm", "Dispatched"].includes(order?.status) && (
        <div className={styles.cancelOrderTooltip}>
          <img src={ToolTipIconRed} alt="tooltip" />
          You can cancel your order before it is dispatched. Once it is
          "Dispatched," cancellation is not possible.
        </div>
      )}

      {/* modals  */}

      <ReviewModal
        reviewModal={reviewModal}
        setReviewModal={setReviewModal}
        orderId={order?.orderItemId}
        productName={order?.variant?.product?.name}
        sku={order.sku}
        setOrderReviewed={setOrderReviewed}
      />
      <CancelOrderModal
        showOrderCancelModal={showOrderCancelModal}
        setShowOrderCancelModal={setShowOrderCancelModal}
        orderId={order?.orderItemId}
        setOrderHasBeenCancel={setOrderHasBeenCancel}
      />
      <ContactSupportModal
        showContactModal={showContactModal}
        setShowContactModal={setShowContactModal}
        orderId={order?.orderItemId}
      />
      {order.trackingId ? (
        <TrackingAvlModal
          showTrackingModal={showTrackingModal}
          setShowTrackingModal={setShowTrackingModal}
          trackingId={order.trackingId}
          trackingSite={order.trackingSite}
        />
      ) : (
        <TrackingNotAvailableModal
          showTrackingModal={showTrackingModal}
          setShowTrackingModal={setShowTrackingModal}
        />
      )}
    </div>
  );
};

export default OrderDetails;
