import React, { useEffect, useRef } from "react";
import styles from "./TrackingNotModal.module.css";

const TrackingNotAvailableModal = ({
  showTrackingModal,
  setShowTrackingModal,
}) => {
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowTrackingModal(false);
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (showTrackingModal === false) return null; // Don't render if not visible

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent} ref={modalRef}>
        <button
          className={styles.closeButton}
          onClick={() => setShowTrackingModal(false)}
        >
          &times;
        </button>
        <h2>Track Your Order</h2>
        <div className={styles.modalIcon}>
          <span>⚠️</span>
        </div>
        <h3>Tracking Not Available</h3>
        <p>
          We're sorry, but tracking information is not available for this order
          at the moment.
        </p>
      </div>
    </div>
  );
};

export default TrackingNotAvailableModal;
