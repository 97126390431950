import React, { useState } from "react";
import styles from "./NewsLetter.module.css";
import Loader from "../../assets/spinnerwhite.svg";
import { subscribeToNewsletter } from "../../services/Newsletter";
const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const handleInputChange = (e) => {
    setMessage("");
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    setBtnLoader(true);
    setMessage("");

    if (!email) {
      setMessage("Please enter a valid email");
      setBtnLoader(false);
      return;
    }

    try {
      const successMessage = await subscribeToNewsletter(email);
      setMessage(successMessage);
      setEmail("");
    } catch (errorMessage) {
      setMessage(errorMessage);
    } finally {
      setBtnLoader(false);
    }
  };

  return (
    <div className={styles.newsletterContainer}>
      <div className={styles.newsletterContent}>
        <h2 className={styles.newsletterTitle}>Stay in the Loop</h2>
        <p className={styles.newsletterDescription}>
          Subscribe to our newsletter for exclusive offers and updates.
        </p>
      </div>
      <div>
        <div className={styles.newsletterForm}>
          <div>
            <input
              type="email"
              className={styles.newsletterInput}
              placeholder="Enter your email"
              value={email}
              onChange={handleInputChange}
            />
          </div>
          <div>
            {btnLoader ? (
              <button
                className={styles.newsletterButtonLoader}
                onClick={handleSubscribe}
              >
                <img src={Loader} alt="loader,," />
              </button>
            ) : (
              <button
                className={styles.newsletterButton}
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            )}
          </div>
        </div>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
};

export default Newsletter;
