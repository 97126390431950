import React from "react";
import Slider from "react-slick";
import styles from "./CustomerReview.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const reviews = [
  {
    name: "Priya Sharma",
    rating: 5,
    text: "The quality of the Bandhani saree I received is exceptional. The intricate patterns and vibrant colors are even more beautiful in person!",
  },
  {
    name: "Anita Patel",
    rating: 4,
    text: "I love my new dupatta from Bandhej Hub. The customer service was excellent, and the shipping was faster than expected.",
  },
  {
    name: "Rahul Desai",
    rating: 5,
    text: "Bought a lehenga for my sister's wedding, and it was a hit! The craftsmanship is truly remarkable. Will definitely shop here again.",
  },
  {
    name: "Sakshi Mehta",
    rating: 4,
    text: "Great collection and top-notch customer service. Will buy again!",
  },
  {
    name: "Aman Verma",
    rating: 5,
    text: "Received my product in excellent condition. The quality is beyond expectation!",
  },
];

const CustomerReview = () => {
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span
          key={i}
          className={
            i <= rating ? `${styles.star} ${styles.filled}` : styles.star
          }
        >
          ★
        </span>
      );
    }
    return stars;
  };

  // Slider settings for responsive behavior
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enables automatic sliding
    autoplaySpeed: 3000, // Time interval between slides (in milliseconds)
    responsive: [
      {
        breakpoint: 1024, // For tablets and below
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For mobile and below
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.reviewsContainer}>
      <h2 className={styles.reviewsTitle}>What Our Customers Say</h2>
      <p className={styles.reviewTagline}>Loved by Many, Trusted by All</p>
      <Slider {...settings} className={styles.slider}>
        {reviews.map((review, index) => (
          <div className={styles.reviewCard} key={index}>
            <div className={styles.reviewStars}>
              {renderStars(review.rating)}
            </div>
            <p className={styles.reviewText}>"{review.text}"</p>
            <p className={styles.reviewAuthor}>{review.name}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomerReview;
