import axiosInstance from "../components/Api/AxiosInstance";

export const subscribeToNewsletter = async (email) => {
  try {
    const response = await axiosInstance.post(
      "/newsletter/subscribe",
      { email },
      { skipAuth: true } // Skips token in request
    );

    return response.data.message;
  } catch (error) {
    console.error("Error subscribing to newsletter:", error);
    if (error?.response?.status === 400) {
      throw error.response.data.message;
    }
    throw "Something went wrong! Please try again later.";
  }
};
