import React, { useState, useEffect, useRef } from "react";
import styles from "./ContactSupportModal.module.css";
import { SlPhone, AiOutlineMail } from "../../../assets/Icons";

const ContactSupportModal = ({
  showContactModal,
  setShowContactModal,
  orderId,
}) => {
  const [tab, setTab] = useState("email");
  const modalRef = useRef(null);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowContactModal(false);
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!showContactModal) return null; // Don't render if not visible

  const truncateOrderId = (orderId) => {
    if (orderId) {
      const n = orderId.length;
      return orderId.slice(n - 8, n);
    }
  };

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modal} ref={modalRef}>
        <button
          className={styles.closeButton}
          onClick={() => setShowContactModal(false)}
        >
          ×
        </button>
        <h2 className={styles.title}>Contact Support</h2>
        <p className={styles.subtitle}>
          Get help with Order{" "}
          <span className={styles.orderId}>#{truncateOrderId(orderId)}</span>
        </p>

        {/* tabs  */}
        <div className={styles.tabs}>
          <button
            className={tab === "email" ? styles.active : ""}
            onClick={() => setTab("email")}
          >
            Email
          </button>
          <button
            className={tab === "phone" ? styles.active : ""}
            onClick={() => setTab("phone")}
          >
            Phone
          </button>
        </div>
        {tab === "email" && (
          <div className={styles.emailSection}>
            <div>
              <h3>Email Support</h3>
              <p>Send us an email and we'll get back to you within 24 hours.</p>
            </div>
            <div>
              <a
                href="mailto:bandhejhub@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                <h6>
                  <AiOutlineMail /> bandhejhub@gmail.com
                </h6>
              </a>
              <p>
                Please include your order number {truncateOrderId(orderId)} in
                your email for faster assistance.
              </p>
            </div>
            </div>
        )}
        {tab === "phone" && (
          <div className={styles.emailSection}>
            <div>
              <h3>Phone Support</h3>
              <p>Available Monday to Friday, 9:00 AM - 6:00 PM</p>
            </div>
            <div>
              <a href="tel:+917740930250" target="_blank" rel="noreferrer">
                <h6>
                  <SlPhone />
                  +91-7740930250
                </h6>
              </a>
              <p>
                Have your order number {truncateOrderId(orderId)} ready for
                quicker assistance.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactSupportModal;
