import React from "react";
import styles from "./TopHeader.module.css";
import {
  PiInstagramLogo,
  RiFacebookFill,
  FaWhatsapp,
  FaYoutube,
  MdOutlineLocalOffer,
  FaTwitter,
} from "../../assets/Icons";
function TopHeader() {
  return (
    <>
      <div className={styles.navtopbanner}>
        <div className={styles.navtopbanner1}>
          <p>Bandhej Hub : Where Tradition Meets Trend</p>
        </div>
        <div className={styles.navtopbanner2}>
          <p>
            Get an Additional 5% Discount on Your First Purchase{" "}
            <MdOutlineLocalOffer />{" "}
          </p>
        </div>

        <div className={styles.navtopbanner3}>
          <div>
            <p>Follow Us :</p>
          </div>
          <div>
            <a
              href="https://www.instagram.com/bandhej_hub/"
              target="_blank"
              rel="noreferrer"
            >
              <PiInstagramLogo />
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/bandhejhub14/"
              target="_blank"
              rel="noreferrer"
            >
              <RiFacebookFill />
            </a>
          </div>
          <div>
            <a
              href="https://www.youtube.com/channel/UCXArGaNnUU_bJGYZJb-WlAA"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube />
            </a>
          </div>
          <div>
            <a href="https://wa.me/7740930250" target="_blank" rel="noreferrer">
              <FaWhatsapp />
            </a>
          </div>
          <div>
            <a href="/">
              <FaTwitter />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopHeader;
