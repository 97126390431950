import axiosInstance from "../components/Api/AxiosInstance";

export const processPayment = async (orderData) => {
  try {
    const response = await axiosInstance.post("/payment", orderData);
    return response.data; // Return only the needed data
  } catch (error) {
    console.error("Payment processing error:", error);
    throw new Error(
      error.response?.data?.message || "Failed to process payment."
    );
  }
};
