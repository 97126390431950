import React, { useContext, useState, useRef, useEffect } from "react";
import styles from "./Login.module.css";
import { RxCross1 } from "../assets/Icons";
import { UserContext } from "../context/userContext";
import axios from "axios";
import { GOOGLE_LOGIN, SEND_OTP } from "../components/Api/Apis";
import Loader from "../assets/spinnerbandhej.svg";
import GoogleIcon from "../assets/GoogleIcon.svg";
import { toast } from "react-toastify";
import { auth, provider, signInWithPopup } from "../firebaseConfig";
import { Link } from "react-router-dom";

const LoginModal = ({ isOpen }) => {
  const { setIsOtpModal, setIsLoginOpen } = useContext(UserContext);
  const modalRef = useRef(null);
  const [loginLoader, setLoginLoader] = useState(false);

  const [buttonLoader, setButtonLoader] = useState(false);
  const [email, setEmail] = useState("");

  const CloseModal = () => {
    setIsLoginOpen(false);
    document.body.style.overflow = "auto";
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      CloseModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (loginLoader) return;
    setButtonLoader(true);

    try {
      await axios.post(
        SEND_OTP,
        {
          email: email,
        },
        {
          withCredentials: true,
        }
      );
      setIsOtpModal(true);
      setIsLoginOpen(false);
      localStorage.setItem("BHUserEmail", email);
      setButtonLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setButtonLoader(false);
    }

    return true;
  };
  const handleLogin = async () => {
    setLoginLoader(true);

    try {
      const result = await signInWithPopup(auth, provider);
      const { displayName, email, uid } = result.user;

      // Call the backend API to handle Google login
      const response = await axios.post(
        GOOGLE_LOGIN,
        {
          name: displayName,
          email,
          uid,
        },
        {
          withCredentials: true,
        }
      );

      // Handle the response from the backend
      if (response.data.success) {
        localStorage.setItem("BandhejHub", response.data.accessToken);
        window.location.href = "/";
      } else {
        toast.error("Login failed");
      }
    } catch (error) {
      toast.error("Login failed");
    } finally {
      setLoginLoader(false);
    }
  };
  if (!isOpen) return null;

  return (
    <>
      <div className={styles.modal}>
        <div ref={modalRef} className={styles.modalContent}>
          <button onClick={CloseModal} className={styles.closeButton}>
            <RxCross1 />
          </button>
          <img
            src="/assets/bhlogo.png"
            alt="Bandhej Hub"
            className={styles.logo}
          />
          <div className={styles.welcomeText}>
            <h3>Welcome to Bandhej Hub</h3>
            <p>Enter your email to continue shopping</p>
          </div>
          <form method="POST" onSubmit={SubmitForm}>
            {/* Email  */}
            <div className={styles.inputGroup}>
              <label htmlFor="email">
                Email address <span>*</span>{" "}
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {buttonLoader ? (
              <img src={Loader} alt="Loading..." className={styles.loader} />
            ) : (
              <button className={styles.loginButton}>Send OTP</button>
            )}
          </form>
          {/* or option  */}
          <div className={styles.continueWit}>
            <span className={styles.line}></span>
            <p>Or continue with</p>
            <span className={styles.line}></span>
          </div>
          {/* google login button   */}
          {loginLoader ? (
            <button disabled className={styles.googleButton}>
              <img src={Loader} alt="Loading..." className={styles.loader} />
            </button>
          ) : (
            <button onClick={handleLogin} className={styles.googleButton}>
              <img src={GoogleIcon} alt="Google" />
              Continue with Google
            </button>
          )}
          <p className={styles.registerbtn}>
            By Confirming, you agree to bandhej Hub's{" "}
            <Link to="/Policies" onClick={CloseModal}>
              Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link to="/Policies" onClick={CloseModal}>
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
