import React, { useContext, useEffect, useState } from "react";
import styles from "./Cart.module.css";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { MdShoppingCartCheckout } from "react-icons/md";
import { toast } from "react-toastify";
import Loader from "../../assets/spinnerwhite.svg";
import Skeleton from "react-loading-skeleton";
import DeleteSvg from "../../assets/delete.svg";
import "react-loading-skeleton/dist/skeleton.css";
import { getUserProfile } from "../../services/User";
import { PromoCodeApply } from "../../services/PromoCode";
const Cart = () => {
  const {
    cartData,
    removeCartData,
    setCartData,
    setCheckoutData,
    Commisions,
    fetchCardLoader,
    accessToken,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [promoLoader, setPromoLoader] = useState(false);
  // {OPEN PRODUCT }
  const handleOpen = (item) => {
    let itemName = item?.variant?.product?.name.replace(/\s+/g, "-");
    navigate(
      `/${item?.variant?.product?.category}/${itemName}/${item?.variant?.productId}?variant=${item?.variant?.sku}`
    );
  };

  let subtotal = 0;

  if (cartData && cartData.length > 0) {
    subtotal = cartData.reduce((acc, item) => {
      if (item && item.variant.product.showPrice !== undefined) {
        const price = Commisions(item.variant.product.showPrice) || 0;
        return acc + price * item?.quantity;
      }
      return acc;
    }, 0);
  }

  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const userData = await getUserProfile();
      setProfile(userData);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (accessToken) fetchUserData();
    else setLoading(false);
  }, []);

  // set promocode
  const [discount, setDiscount] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [navigatePpromoCode, setNavigatePpromoCode] = useState("");
  const submitPromoCode = async (e) => {
    e.preventDefault();
    setPromoLoader(true);

    try {
      const response = await PromoCodeApply(promoCode, subtotal);

      setNavigatePpromoCode(promoCode);
      setDiscount(response); // Ensure the service function returns response.data
      toast.success("Promo Code Applied");
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to apply promo code"
      );
    } finally {
      setPromoLoader(false);
    }
  };

  // {CHECKOUT FUNCTION }
  const handleCheckout = () => {
    setCheckoutData(cartData);
    if (navigatePpromoCode === "") {
      navigate("/checkout");
    } else navigate(`/checkout?promocode=${navigatePpromoCode}`);
  };
  const removeCartDataFunc = async (sku) => {
    setCartData((prevData) => prevData.filter((item) => item.sku !== sku));
    await removeCartData(sku);
  };

  if (fetchCardLoader || loading) {
    return (
      <div className={styles.cartLoadingMainSection}>
        <div className={styles.cartLoadingSection}>
          <div>
            <Skeleton height={110} width={100} />
          </div>

          <div>
            {window.innerWidth > 550 ? (
              <>
                <Skeleton height={25} width={300} />
                <Skeleton height={20} width={200} />
                <Skeleton height={20} width={150} />
              </>
            ) : (
              <>
                <Skeleton height={22} width={150} />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={60} />
              </>
            )}
          </div>
        </div>
        <div className={styles.cartLoadingSection}>
          <div>
            <Skeleton height={110} width={100} />
          </div>

          <div>
            {window.innerWidth > 550 ? (
              <>
                <Skeleton height={25} width={300} />
                <Skeleton height={20} width={200} />
                <Skeleton height={20} width={150} />
              </>
            ) : (
              <>
                <Skeleton height={22} width={150} />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={60} />
              </>
            )}
          </div>
        </div>
        <div className={styles.cartLoadingSection}>
          <div>
            <Skeleton height={110} width={100} />
          </div>

          <div>
            {window.innerWidth > 550 ? (
              <>
                <Skeleton height={25} width={300} />
                <Skeleton height={20} width={200} />
                <Skeleton height={20} width={150} />
              </>
            ) : (
              <>
                <Skeleton height={22} width={150} />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={60} />
              </>
            )}
          </div>
        </div>
      </div>
    );
  } else if (cartData?.length === 0 || !cartData) {
    return (
      <div className={styles.cartDataNotFound}>
        <div>
          <img src="/assets/cart.png" alt="" />
        </div>
        <div>
          <h3 className="cart-data-not-available">Your cart is empty</h3>
          <p>
            You have no items in your shopping cart <br /> Let's go buy
            something
          </p>
        </div>
        <div>
          <Link to="/">
            <button>Shop Now</button>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className={styles.cartMainSection}>
        <div className={styles.cartSection}>
          <table>
            <thead>
              <tr>
                <th>PRODUCT</th>
                <th>PRICE</th>
                <th>QUANTITY</th>
                <th>SUBTOTAL</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {cartData?.map((item) => (
                <tr className={styles.cartContent} key={item?.cartId}>
                  <td>
                    <div className={styles.cartImagesDetail}>
                      <div>
                        <img
                          style={{ cursor: "pointer" }}
                          src={item?.variant?.image}
                          onClick={() => handleOpen(item)}
                          alt="Loading..."
                        />
                      </div>

                      <div className={styles.cartDetail}>
                        <p>{item?.variant?.product?.name}</p>
                        <p>
                          Color: <b>{item?.variant?.color}</b>
                        </p>
                      </div>
                    </div>
                  </td>

                  <td>
                    <p>
                      Rs.
                      {/* {item?.variant?.product?.showPrice?.toFixed(2) || 0} */}
                      {Commisions(item?.variant?.product?.showPrice)}.00
                    </p>
                  </td>
                  <td>{item?.quantity}</td>
                  <td>
                    Rs.{" "}
                    {(
                      (Commisions(item?.variant?.product?.showPrice) || 0) *
                      (item?.quantity || 0)
                    ).toFixed(2)}
                  </td>
                  <td>
                    <p onClick={() => removeCartDataFunc(item?.variant?.sku)}>
                      <img
                        src={DeleteSvg}
                        alt="X"
                        style={{ width: "20px", cursor: "pointer" }}
                      />
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className={styles.cartSummary}>
          <div className={styles.cartAmount}>
            <h2>Summary</h2>
            {/* promocode input   */}
            <div className={styles.promoCode}>
              <form onSubmit={submitPromoCode}>
                  <input
                    type="text"
                    required
                    placeholder="Promo Code"
                    name="promo"
                    onChange={(e) => setPromoCode(e.target.value)}
                  />
                <div>
                  {promoLoader ? (
                    <button type="submit" disabled>
                      <img src={Loader} alt="" />
                    </button>
                  ) : (
                    <button type="submit">Submit</button>
                  )}
                </div>
              </form>
            </div>

            {/* subtotal  */}
            <div className={styles.cartAmountRupee}>
              <h3>Subtotal</h3>
              <h3>Rs.{subtotal}</h3>
            </div>
            {/* promocode  */}
            {profile?.is_first_purchase && (
              <div className={`${styles.cartAmountRupee} ${styles.highlight}`}>
                <h3>First Purchase(5%)</h3>
                <h3> -Rs.{(subtotal * 0.05).toFixed(0)}</h3>
              </div>
            )}
            <div className={`${styles.cartAmountRupee} ${styles.highlight}`}>
              <h3>Discount</h3>
              <h3> -Rs.{discount.toFixed(0)}</h3>
            </div>
            {/* delivery  */}
            <div className={styles.cartAmountRupee}>
              <h3>Delivery</h3>
              <i className={styles.freeDeliveryTag}>Free Delivery</i>
              {/* <h3>Rs.0</h3> */}
            </div>
            {/* total  */}
            <div className={styles.cartAmountRupee}>
              <h3>Total</h3>
              {profile?.is_first_purchase ? (
                <h3>
                  Rs.{Math.round(Number(subtotal) - discount - subtotal * 0.05)}
                </h3>
              ) : (
                <h3>Rs.{Math.round(Number(subtotal) - discount)}</h3>
              )}
            </div>

            <div className={styles.cartAmountButtons}>
              <button onClick={handleCheckout}>
                Checkout <MdShoppingCartCheckout />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
