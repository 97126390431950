import axiosInstance from "../components/Api/AxiosInstance";

export const PromoCodeApply = async (promoCode, totalAmount) => {
  try {
    const response = await axiosInstance.post(
      "/promocode/validate",
      { promoCode, totalAmount }, // Correct body structure
      { skipAuth: true } // If this is meant to be a request config, it should be the third argument
    );

    return response.data.discount;
  } catch (error) {
    console.error("Error applying promo code:", error);
    throw new Error(
      error.response?.data?.message || "Failed to apply promo code."
    );
  }
};
