import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { USER_LOGOUT } from "../components/Api/Apis";
import { toast } from "react-toastify";
import { addToCart, getCartData, removeCartItem } from "../services/Cart";
import {
  addToWishlist,
  fetchWishlist,
  removeFromWishlist,
} from "../services/Wishlist";
import { getUserProfile } from "../services/User";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // FETCH USER DATA
  const [itemCount, setItemCount] = useState(0);
  const [cartData, setCartData] = useState(null);
  const [checkoutData, setCheckoutData] = useState(null);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isOtpModal, setIsOtpModal] = useState(false);
  const [cartLoader, setCartLoader] = useState(false);
  let accessToken = localStorage.getItem("BandhejHub");

  const handleSignOut = async () => {
    const logout = window.confirm("Are you sure you want to log out?");
    if (logout) {
      try {
        await axios.post(USER_LOGOUT, {}, { withCredentials: true });
        localStorage.removeItem("BandhejHub");
        window.location.href = "/";
      } catch (error) {
        if (error.response.status === 404) {
          localStorage.removeItem("BandhejHub");
          window.location.href = "/";
        }
      }
    }
  };

  //ADD TO CART
  const handleAddToCart = async (quantity, sku) => {
    setCartLoader(true);

    if (!accessToken) {
      document.body.style.overflow = "hidden";
      setIsLoginOpen(true);
      toast.error("Please login to add product to cart.");
      setCartLoader(false);
      return;
    }

    try {
      await addToCart(quantity, sku, accessToken);
      fetchCartData();
      toast.success("Product added successfully!");
    } catch (error) {
      console.error("Add to cart error:", error.message);
      toast.error(error.message);
    } finally {
      setCartLoader(false); // Ensures the loader stops in both success and error cases
    }
  };

  // REMOVE FROM CART
  const removeCartData = async (sku) => {
    try {
      await removeCartItem(sku);
      toast.success("Product removed from cart.");
    } catch (error) {
      console.error("Remove cart error:", error.message);
      toast.error(error.message);
    }
  };

  // GET CARD DATA
  const [fetchCardLoader, setFetchCardLoader] = useState(true);
  const fetchCartData = async () => {
    setFetchCardLoader(true);
    try {
      const cartData = await getCartData();
      setCartData(cartData.reverse());
    } catch (error) {
      console.error("Fetch cart error:", error.message);
      setCartData(null);
    } finally {
      setFetchCardLoader(false); // Ensures loader stops in both success and error cases
    }
  };

  useEffect(() => {
    if (accessToken) fetchCartData();
    else setFetchCardLoader(false);
  }, []);

  const Commisions = (price) => {
    // let CommisionPrice = 0;
    // if (price < 200) return price;
    // else if (price >= 200 && price < 500) CommisionPrice = price + price * 0.12;
    // else if (price >= 500 && price < 1000)
    //   CommisionPrice = price + price * 0.08;
    // else CommisionPrice = price + price * 0.07;
    // return Math.round(CommisionPrice);
    return price;
  };

  const [wishListData, setWishListData] = useState(null);
  const [FetchWishlistDataLoader, setFetchWishlistDataLoader] = useState(true);

  const FetchWishlistData = async () => {
    setFetchWishlistDataLoader(true);

    try {
      const data = await fetchWishlist();
      setWishListData(data);
    } catch (error) {
      console.error("Fetch wishlist error:", error.message);
      setWishListData(null);
    } finally {
      setFetchWishlistDataLoader(false); // Ensures loader stops in both success and error cases
    }
  };
  useEffect(() => {
    if (accessToken) FetchWishlistData();
    else setFetchWishlistDataLoader(false);
  }, []);

  const AddToWishList = async (sku) => {
    try {
      await addToWishlist(sku);
      FetchWishlistData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const removedWishlistItem = async (sku) => {
    try {
      await removeFromWishlist(sku);
      FetchWishlistData();
    } catch (error) {}
  };
  const [profile, setProfile] = useState(null);

  const fetchUserData = async () => {
    try {
      const userData = await getUserProfile();
      setProfile(userData);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (accessToken) fetchUserData();
  }, []);

  return (
    <UserContext.Provider
      value={{
        accessToken,
        itemCount,
        setItemCount,
        fetchCardLoader,
        cartData,
        setCartData,
        removeCartData,
        profile,
        checkoutData,
        handleSignOut,
        setCheckoutData,
        cartLoader, // loader for add cart button
        handleAddToCart, // ADD CART FUNCTION
        isLoginOpen,
        setIsLoginOpen,
        isOtpModal,
        setIsOtpModal,
        Commisions,
        AddToWishList,
        wishListData,
        setWishListData,
        FetchWishlistDataLoader,
        FetchWishlistData,
        removedWishlistItem,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
