import axios from "axios";
import { toast } from "react-toastify";
import { AUTH_TOKEN, USER_LOGOUT } from "./Apis";

let API_BASE_URL;
if (process.env.NODE_ENV === "production") {
  API_BASE_URL = process.env.REACT_APP_HOST_SERVER_KEY;
} else if (process.env.NODE_ENV === "development") {
  API_BASE_URL = process.env.REACT_APP_LOCAL_SERVER_KEY;
}

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to check if the token is expired
const isTokenExpired = (token) => {
  try {
    const tokenParts = token.split(".");
    if (tokenParts?.length !== 3) {
      throw new Error("Invalid token format");
    }

    // Decode the payload part (base64)
    const payload = JSON.parse(atob(tokenParts[1]));

    // Get the current time in seconds
    const currentTime = Math.floor(Date.now() / 1000);

    // Compare the current time with the token's expiration time
    if (payload.exp && payload.exp < currentTime) {
      return true; // Token is expired
    } else {
      return false; // Token is valid
    }
  } catch (error) {
    return true; // Treat as expired if there's an error
  }
};

// Function to refresh the access token
const refreshAccessToken = async () => {
  console.log("token refreshing");
  try {
    const response = await axios.post(
      AUTH_TOKEN,
      {},
      { withCredentials: true }
    );

    // If refresh token is expired
    const { accessToken } = response.data;
    localStorage.setItem("BandhejHub", accessToken);
    return accessToken;
  } catch (error) {
    if (error.response.status === 404) {
      toast.error("Oops! Session has expired");
      localStorage.removeItem("BandhejHub");
      window.alert("Session has expired");
      window.location.href = "/";
    } else if (error.response.status === 401) {
      toast.error("Oops! Session has expired");
      window.alert("Session has expired");
      await axios.post(
        USER_LOGOUT,
        {},
        {
          withCredentials: true,
        }
      );
      localStorage.removeItem("BandhejHub");
      window.location.href = "/";
    }
    console.log(error.response.status);
  }
};

// Request Interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Check if 'skipAuth' flag is present
    if (!config.skipAuth) {
      let token = localStorage.getItem("BandhejHub");
      if (token) {
        if (isTokenExpired(token)) {
          token = await refreshAccessToken();
        }
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     if (error.response && error.response.status === 401) {
//       console.error("Unauthorized! Redirecting to login...");
//       localStorage.removeItem("BandhejHub");
//       window.location.href = "/login"; // Redirect to login page
//     }
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
