import React, { useContext, useState } from "react";
import styles from "./Login.module.css";
import { RxCross1 } from "../assets/Icons";
import { UserContext } from "../context/userContext";
import axios from "axios";
import { VERIFY_OTP } from "../components/Api/Apis";
import Loader from "../assets/spinnerbandhej.svg";
import { toast } from "react-toastify";

const OtpVerification = () => {
  const { isOtpModal, setIsOtpModal } = useContext(UserContext);

  const [buttonLoader, setButtonLoader] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const email = localStorage.getItem("BHUserEmail");
  const CloseModal = () => {
    setIsOtpModal(false);
    document.body.style.overflow = "auto";
  };

  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus the next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleSubmit = async () => {
    const enteredOtp = otp.join("");
    setButtonLoader(true);

    try {
      const response = await axios.post(
        VERIFY_OTP,
        {
          email: email,
          Userotp: enteredOtp,
        },
        {
          withCredentials: true,
        }
      );
      localStorage.setItem("BandhejHub", response.data.accessToken);
      localStorage.removeItem("BHUserEmail");
      window.location.href = "/";
      setButtonLoader(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setButtonLoader(false);
    }

    return true;
  };

  if (!isOtpModal) return null;
  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <button onClick={CloseModal} className={styles.closeButton}>
          <RxCross1 />
        </button>
        <img
          src="/assets/bhlogo.png"
          alt="Bandhej Hub"
          className={styles.logo}
        />
        <h2 className={styles.title}>OTP Verification</h2>
        <p className={styles.description}>We’ve sent an OTP to</p>
        <p className={styles.description}>
          <b>{email}</b>{" "}
          {/* <a href="#" className={styles.reenterEmail}>
            Edit
          </a> */}
        </p>

        <div className={styles.otpInputs}>
          {otp.map((_, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="number"
              maxLength="1"
              className={styles.otpInput}
              value={otp[index]}
              onChange={(e) => handleChange(e.target.value, index)}
            />
          ))}
        </div>
        {buttonLoader ? (
          <img
            src={Loader}
            alt="Loading..."
            className={styles.loader}
            style={{ marginBottom: "20px" }}
          />
        ) : (
          <button className={styles.verifyButton} onClick={handleSubmit}>
            Verify OTP
          </button>
        )}

        {/* <p className={styles.description}>
          Didn’t receive OTP?{" "}
          <a href="#" className={styles.reenterEmail}>
            Resend
          </a>
        </p> */}
      </div>
    </div>
  );
};

export default OtpVerification;
