import React, { useState, useEffect, useRef } from "react";
import styles from "./CancelOrder.module.css";
import ToolTipIconRed from "../../../assets/tooltipIconRed.svg";
import Loader from "../../../assets/spinnerwhite.svg";
import { toast } from "react-toastify";
import { cancelOrder } from "../../../services/Order";
import { truncateOrderId } from "../../../utils";
const CancelOrderModal = ({
  showOrderCancelModal,
  setShowOrderCancelModal,
  orderId,
  setOrderHasBeenCancel,
}) => {
  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");
  const modalRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowOrderCancelModal(false);
      document.body.style.overflow = "auto";
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!showOrderCancelModal) return null; // Don't render if not visible

  const handleCancelOrder = async () => {
    setLoading(true);
    try {
      await cancelOrder(orderId, reason, comments);
      toast.success("Order canceled successfully!");
      setShowOrderCancelModal(false);
      document.body.style.overflow = "auto";
      setOrderHasBeenCancel(true);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modal} ref={modalRef}>
        <button
          className={styles.closeButton}
          onClick={() => setShowOrderCancelModal(false)}
        >
          ×
        </button>
        <h2 className={styles.title}>
          Cancel Order{" "}
          <span className={styles.orderId}>#{truncateOrderId(orderId)}</span>
        </h2>
        <p className={styles.subtitle}>
          Are you sure you want to cancel this order? This action cannot be
          undone.
        </p>

        <div className={styles.warning}>
          <img src={ToolTipIconRed} alt="tooltip" />
          <div>
            <h5>Warning</h5>
            <p>
              Canceling this order may affect your refund and future order
              processing.
            </p>
          </div>
        </div>

        <div className={styles.formGroup}>
          <label>Reason for cancellation</label>
          <select
            className={styles.select}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          >
            <option value="">Select a reason</option>
            <option value="ordered_wrong_item">Ordered Wrong Item</option>
            <option value="shipping_time_too_long">
              Shipping time too long
            </option>
            <option value="changed_my_mind">Changed my mind</option>
            <option value="found_better_price">
              Found better price elsewhere
            </option>
            <option value="duplicate_order">Duplicate order</option>
            <option value="other_reason">Other reason</option>
          </select>
        </div>

        <div className={styles.formGroup}>
          <label>Additional comments (optional)</label>
          <textarea
            className={styles.textarea}
            placeholder="Please provide any additional details..."
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          ></textarea>
        </div>

        <div className={styles.buttonGroup}>
          {loading ? (
            <button className={styles.keepOrder}>Keep Order</button>
          ) : (
            <button
              className={styles.keepOrder}
              onClick={() => setShowOrderCancelModal(false)}
            >
              Keep Order
            </button>
          )}
          {reason === "" ? (
            <button className={styles.cancelOrderDisable} disabled>
              Cancel Order
            </button>
          ) : loading ? (
            <button className={styles.cancelOrder}>
              <img src={Loader} alt="" />
            </button>
          ) : (
            <button className={styles.cancelOrder} onClick={handleCancelOrder}>
              Cancel Order
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CancelOrderModal;
