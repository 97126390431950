import React from "react";
import styles from "./Footer.module.css";
import {
  PiInstagramLogo,
  IoLocationOutline,
  AiOutlineMail,
  SlPhone,
  RiFacebookFill,
  FaWhatsapp,
  FaYoutube,
  FaTwitter,
} from "../../assets/Icons";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div
            className={`${styles.footerLeftSection} ${styles.footerSection}`}
          >
            <div>
              <img src="/assets/bhlogo.png" alt="Loading.." />
            </div>

            <div>
              <p>
                "Bandhej Hub offers a wide range of women's clothing, from
                beautiful sarees to trendy dresses and kurtis, bringing together
                a variety of styles for every woman. Our goal is to make it easy
                for you to find the perfect outfit for any occasion, while
                supporting local designers and artisans. At Bandhej Hub, we
                celebrate fashion that blends tradition with modern trends,
                making every woman feel confident and stylish."{" "}
              </p>
            </div>
          </div>

          <div
            className={`${styles.footerMiddleSection} ${styles.footerSection}`}
          >
            <div>
              <h2>Customer help</h2>
            </div>

            <div>
              <Link to="/Policies">Shipping Policy</Link>
            </div>
            <div>
              <Link to="/Policies">Return & Refund Policy</Link>
            </div>

            <div>
              <Link to="/Policies">Privacy Policy</Link>
            </div>
            <div>
              <Link to="/Policies">Terms of Service</Link>
            </div>
            {/* <div>
              <Link to="/">About Us</Link>
            </div>
            <div>
              <Link to="/">FAQ's</Link>
            </div> */}
          </div>

          <div
            className={`${styles.footerMiddleSection} ${styles.footerMiddle2Section} ${styles.footerSection}`}
          >
            <div>
              <h2>Quick Shop</h2>
            </div>

            {/* <div>
              <Link to="/Top-Trending">Top Trending</Link>
            </div>
            <div>
              <Link to="/Recomanded">Recomanded Item</Link>
            </div>
            <div>
              <Link to="/">Super Sale</Link>
            </div> */}
            <div>
              <Link to="/Saree">Saree</Link>
            </div>
            <div>
              <Link to="/Dupatta">Dupatta</Link>
            </div>
            <div>
              <Link to="/Dress">Dress</Link>
            </div>
            <div>
              <Link to="/Lehanga">Lehanga</Link>
            </div>
          </div>

          <div
            className={`${styles.footerRightSection} ${styles.footerSection}`}
          >
            <div>
              <h2>Get in Touch</h2>
            </div>

            <div>
              <i>
                <IoLocationOutline />
              </i>
              <span>
                {" "}
                New Roshan Ganj, Ward No.2, Sikar, Rajasthan - 332001
              </span>
            </div>
            <div>
              <i>
                <AiOutlineMail />
              </i>
              <span>bandhejhub@gmail.com</span>
            </div>
            <div>
              <i>
                <SlPhone />
              </i>
              <span>+91-7740930250</span>
            </div>

            <div className={styles["footerRightSection-media-icons"]}>
              <div>
                <a href="https://www.instagram.com/bandhej_hub/">
                  <i>
                    <PiInstagramLogo />
                  </i>
                </a>
              </div>
              <div>
                <a href="https://www.facebook.com/bandhejhub14/">
                  <i>
                    <RiFacebookFill />
                  </i>
                </a>
              </div>
              <div>
                <a href="https://www.youtube.com/channel/UCXArGaNnUU_bJGYZJb-WlAA">
                  <i>
                    <FaYoutube />
                  </i>
                </a>
              </div>
              <div>
                <a href="https://wa.me/7740930250">
                  <i>
                    <FaWhatsapp />
                  </i>
                </a>
              </div>
              <div>
                <a href="/">
                  <i>
                    <FaTwitter />
                  </i>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.footerCopyRight}>
          <p>Copyright © 2025 All rights reserved | Bandhej Hub India</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
