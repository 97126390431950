import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ProductCard.module.css";
import { UserContext } from "../../context/userContext";
import CartIcon from "../../assets/cart.svg";
import HeartIcon from "../../assets/heart.svg";
import RedHeartIcon from "../../assets/red-heart.svg";
import Loader from "../../assets/spinnerwhite.svg";
import { toast } from "react-toastify";
import FullStarIcon from "../../assets/RatingFullStarIcon.svg";
import BlankStarIcon from "../../assets/RatingBlankStarIcon.svg";
import { getVariantReviews } from "../../services/Product";
import { calculateAverageRating } from "../../utils";
const ProductCard = ({ product }) => {
  const [reviews, setReviews] = useState([]);
  const rating = useMemo(() => calculateAverageRating(reviews), [reviews]);
  const navigate = useNavigate();
  const {
    handleAddToCart,
    AddToWishList,
    wishListData,
    removedWishlistItem,
    accessToken,
    setIsLoginOpen,
  } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(
    wishListData?.some(
      (item) => item.variant.productId === product.variants[0].productId
    )
  );

  // get variant reviews
  useEffect(() => {
    const fetchReviews = async () => {
      if (!product?.variants?.length) return; // Prevent errors if variants are missing
      try {
        const reviewsData = await getVariantReviews(product.variants[0].sku);
        setReviews(reviewsData);
      } catch (errorMessage) {
        console.error(errorMessage);
      }
    };

    fetchReviews();
  }, [product]);

  const handleNavigate = () => {
    navigate(
      `/${product?.category}/${product?.name?.replace(/\s+/g, "-")}/${
        product?.productId
      }?variant=${product?.variants[0]?.sku}`
    );
  };

  const handleAddToCartClick = async () => {
    setLoading(true);
    await handleAddToCart(1, product?.variants[0].sku);
    setLoading(false);
  };

  const handleWishlistClick = () => {
    if (!accessToken) {
      document.body.style.overflow = "hidden";
      setIsLoginOpen(true);
      toast.error("Please Login for add product in wishlist");
    } else {
      setIsInWishlist(!isInWishlist);
      if (isInWishlist) {
        removedWishlistItem(product?.variants[0].sku);
      } else {
        AddToWishList(product?.variants[0].sku);
      }
    }
  };

  return (
    <>
      <div className={styles.productCard}>
        {/* Icons on hover */}
        <div className={styles.iconContainer}>
          <button onClick={handleWishlistClick}>
            <img
              src={isInWishlist ? RedHeartIcon : HeartIcon}
              alt="Add to Wishlist"
            />
          </button>
          {/* <button onClick={() => setProductModal(true)}>
            <img src={ViewIcon} alt="View Product" />
          </button> */}
        </div>
        {/* {Discount tag } */}
        {product?.discount && (
          <p className={styles.productCardTag}>
            <span>{product?.discount}% OFF</span>
          </p>
        )}
        {/* <p className={styles.newProduct}>
          <span>NEW</span>
        </p> */}
        {/* Image  */}
        <div
          className={`${styles.productImageContainer} ${
            !product?.variants[0].stock ? styles.outOfStockImage : ""
          }`}
          onClick={handleNavigate}
        >
          <img src={product?.variants[0]?.image} alt={product?.name} />
        </div>
        <div className={styles.productInfo}>
          {/* category  and stock*/}
          <div className={styles.stockCategory}>
            <div className={styles.productCategory}>{product?.category}</div>
            <div className={styles.productStock}>
              {product?.variants[0].stock ? (
                <span className={styles.InStock}>• In Stock</span>
              ) : (
                <span className={styles.OutStock}>• Out Stock</span>
              )}
            </div>
          </div>
          {/* product Name  */}
          <p className={styles.productName}>{product?.name}</p>
          {/* product review  */}
          <div className={styles.rating}>
            <div className={styles.ratingStar}>
              {[...Array(5)].map((_, index) => (
                <img
                  key={index}
                  src={index < rating ? FullStarIcon : BlankStarIcon}
                  alt="star"
                />
              ))}
            </div>

            <div>
              <p>({reviews.length} reviews)</p>
            </div>
          </div>
          {/* product price  */}
          <div className={styles.productInfoPrice}>
            <div>
              <p>₹{product?.showPrice}.00</p>
            </div>
            {product?.showPrice !== product?.price && (
              <div>
                <p>₹{product?.price}.00</p>
              </div>
            )}
          </div>
          {/* buttons  */}
          {product?.variants[0].stock ? (
            <div className={styles.productInfoPriceCartBtn}>
              <button onClick={handleAddToCartClick} disabled={loading}>
                {loading ? (
                  <img src={Loader} alt="Loading..." />
                ) : (
                  <>
                    <img src={CartIcon} alt="Add to Cart" />
                    Add To Cart
                  </>
                )}
              </button>
            </div>
          ) : (
            <div
              className={`${styles.productInfoPriceCartBtn} ${styles.outOfStockBtn}`}
            >
              <button disabled>
                <img src={CartIcon} alt="Add to Cart" />
                Out of Stock
              </button>
            </div>
          )}
        </div>
      </div>
      {/* <ProductDetails */}
    </>
  );
};

export default ProductCard;
