import axiosInstance from "../components/Api/AxiosInstance";

export const getUserProfile = async () => {
  try {
    const response = await axiosInstance.get("/user/profile"); // Adjust endpoint
    return response.data.user;
  } catch (error) {
    throw new Error("Failed to fetch user profile");
  }
};

export const updateUserProfile = async (name, phone) => {
  try {
    const response = await axiosInstance.patch("/user/update-profile", {
      name,
      phone,
    });
    return response.data;
  } catch (error) {
    console.error("Profile update error:", error);
    throw new Error(
      error.response?.data?.message || "Failed to update profile."
    );
  }
};
