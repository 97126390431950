import axiosInstance from "../components/Api/AxiosInstance";

export const getAllProducts = async () => {
  try {
    const response = await axiosInstance.get("/product", { skipAuth: true }); // No auth required
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error.response?.data?.message || "An error occurred";
  }
};

export const getVariantReviews = async (sku) => {
  try {
    const response = await axiosInstance.get(
      `/product/variant/reviews/${sku}`,
      {
        skipAuth: true, // No authentication required
      }
    );
    return response.data.reviews;
  } catch (error) {
    console.error("Error fetching reviews:", error);
    throw (
      error.response?.data?.message ||
      "An error occurred while fetching reviews."
    );
  }
};

export const getProductById = async (productId) => {
  try {
    const response = await axiosInstance.get(`/product/${productId}`, {
      skipAuth: true, // No authentication required
    });
    return response.data.product;
  } catch (error) {
    console.error("Error fetching product:", error);
    throw error.response?.data?.message || "Failed to fetch product.";
  }
};
