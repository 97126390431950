import React from "react";
import AllPolicies from "../components/Policies/Policies";
import TopHeader from "../components/TopHeader/TopHeader";
import Navbar from "../components/Navbar/Navbar";
const Policies = () => {
  return (
    <div>
      <TopHeader />
      <Navbar />
      <AllPolicies />
    </div>
  );
};

export default Policies;
