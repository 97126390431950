import axiosInstance from "../components/Api/AxiosInstance";

export const fetchOrders = async (status) => {
  try {
    const response = await axiosInstance.get(`/order/${status}`);
    return response.data.orders.reverse(); // Reverse orders before returning
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error.response?.data?.message || "Order not found";
  }
};

export const getOrderById = async (orderId) => {
  try {
    const response = await axiosInstance.get(`/order/getOrder/${orderId}`);
    return response.data.orders;
  } catch (error) {
    console.error("Error fetching order details:", error);
    throw error.response?.data?.message || "Failed to fetch order details.";
  }
};

export const cancelOrder = async (orderId, reason, comments) => {
  try {
    await axiosInstance.patch("/order/cancel", {
      orderItemId: orderId,
      canceled_reason: reason,
      additional_cancel_comment: comments,
    });

    return { success: true, message: "Order canceled successfully!" };
  } catch (error) {
    console.error("Error canceling order:", error);
    throw error.response?.data?.message || "Failed to cancel order.";
  }
};

export const submitReview = async ({ orderId, sku, rating, comment }) => {
    try {
      await axiosInstance.post("/order/rating", {
        orderItemId: orderId,
        sku,
        rating,
        comment,
      });
  
      return { success: true, message: "Thanks For Submitting Review" };
    } catch (error) {
      console.error("Error submitting review:", error);
      throw error.response?.data?.message || "Failed to submit review.";
    }
  };