import React, { useContext, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./WishlistCard.module.css";
import { UserContext } from "../../context/userContext";
import CartIcon from "../../assets/cart.svg";
import HeartIcon from "../../assets/heart.svg";
import RedHeartIcon from "../../assets/red-heart.svg";
import Loader from "../../assets/spinnerwhite.svg";
import FullStarIcon from "../../assets/RatingFullStarIcon.svg";
import BlankStarIcon from "../../assets/RatingBlankStarIcon.svg";
import { calculateAverageRating } from "../../utils";
import { getVariantReviews } from "../../services/Product";

const WishlistCard = ({ product }) => {
  const navigate = useNavigate();
  const {
    handleAddToCart,
    Commisions,
    wishListData,
    setWishListData,
    removedWishlistItem,
    accessToken,
  } = useContext(UserContext);
  const [reviews, setReviews] = useState([]);
  const rating = useMemo(() => calculateAverageRating(reviews), [reviews]);

  const [loading, setLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState(
    wishListData?.some(
      (item) => item.variant.productId === product.variant.productId
    )
  );
  const fetchReviews = async () => {
    try {
      const reviewsData = await getVariantReviews(product.variant.sku);
      setReviews(reviewsData);
    } catch (errorMessage) {
      console.error(errorMessage);
    }
  };
  useEffect(() => {
    if (accessToken) fetchReviews();
  }, []);

  const handleNavigate = () => {
    navigate(
      `/${
        product.variant.product?.category
      }/${product.variant?.product.name?.replace(/\s+/g, "-")}/${
        product?.variant.productId
      }?variant=${product?.variant?.sku}`
    );
  };

  const truncateText = (text) => {
    if (window.innerWidth <= 775) {
      if (text.length < 20) return text;
      return text.slice(0, 20) + "...";
    } else {
      if (text.length < 25) return text;
      return text.slice(0, 25) + "...";
    }
  };

  const handleAddToCartClick = async () => {
    setLoading(true);
    await handleAddToCart(1, product?.variant.sku);
    setLoading(false);
  };

  const handleWishlistClick = (wishlistId) => {
    setIsInWishlist(!isInWishlist);

    // Update the wishlist state by removing the item with the given wishlistId
    setWishListData((prevWishListData) =>
      prevWishListData.filter((item) => item.wishlistId !== wishlistId)
    );

    // Perform additional logic if needed
    removedWishlistItem(product?.variant.sku);
  };

  return (
    <>
      <div className={styles.productCard}>
        {/* Icons on hover */}
        <div className={styles.iconContainer}>
          <button onClick={() => handleWishlistClick(product?.wishlistId)}>
            <img
              src={isInWishlist ? RedHeartIcon : HeartIcon}
              alt="Add to Wishlist"
            />
          </button>
          {/* <button onClick={() => setProductModal(true)}>
            <img src={ViewIcon} alt="View Product" />
          </button> */}
        </div>
        {/* {Discount tag } */}
        {product.variant.product?.discount && (
          <p className={styles.productCardTag}>
            <span>{product.variant.product?.discount}% OFF</span>
          </p>
        )}
        {/* <p className={styles.newProduct}>
          <span>NEW</span>
        </p> */}
        {/* Image  */}
        <div
          className={`${styles.productImageContainer} ${
            !product?.variant.stock ? styles.outOfStockImage : ""
          }`}
          onClick={handleNavigate}
        >
          <img
            src={product?.variant?.image}
            alt={product.variant.product?.name}
          />
        </div>
        <div className={styles.productInfo}>
          {/* category  and stock*/}
          <div className={styles.stockCategory}>
            <div className={styles.productCategory}>
              {product.variant.product?.category}
            </div>
            <div className={styles.productStock}>
              {product?.variant.stock ? (
                <span className={styles.InStock}>• In Stock</span>
              ) : (
                <span className={styles.OutStock}>• Out Stock</span>
              )}
            </div>
          </div>
          {/* product Name  */}
          <p className={styles.productName}>
            {truncateText(product.variant?.product.name)}
          </p>
          {/* product review  */}
          <div className={styles.rating}>
            <div className={styles.ratingStar}>
              {[...Array(5)].map((_, index) => (
                <img
                  key={index}
                  src={index < rating ? FullStarIcon : BlankStarIcon}
                  alt="star"
                />
              ))}
            </div>

            <div>
              <p>({reviews.length} reviews)</p>
            </div>
          </div>

          {/* product price  */}
          <div className={styles.productInfoPrice}>
            <div>
              <p>₹{Commisions(product.variant.product?.showPrice)}.00</p>
            </div>
            <div>
              <p>₹{product.variant.product?.price}.00</p>
            </div>
          </div>
          {/* buttons  */}
          <div className={styles.productInfoPriceCartBtn}>
            <button onClick={handleAddToCartClick} disabled={loading}>
              {loading ? (
                <img src={Loader} alt="Loading..." />
              ) : (
                <>
                  <img src={CartIcon} alt="Add to Cart" />
                  Add To Cart
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WishlistCard;
