import React, { useEffect, useRef, useState } from "react";
import styles from "./FilterSidebar.module.css";
import CrossIcon from "../../assets/CrossIcon.svg";
import { toast } from "react-toastify";
import { getAllProducts } from "../../services/Product";
import DropdownIcon from "../../assets/dropdownIcon.svg";
const FilterSidebar = ({ isOpen, onClose }) => {
  const sidebarRef = useRef(null);
  const [allProducts, setAllProducts] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(""); // State to track selected category
  const [selectFabric, setSelectFabric] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [price, setPrice] = useState(2500);

  const [isCollectionsOpen, setIsCollectionsOpen] = useState(false);
  const [isFabricOpen, setIsFabricOpen] = useState(false);
  const [isOccasionOpen, setIsOccasionOpen] = useState(false);

  useEffect(() => {
    if (!search && !selectedCategory && !selectFabric) {
      setSearchResults([]);
      return;
    }
    const results = allProducts?.products?.filter((product) => {
      const matchesCategory = selectedCategory
        ? product.category === selectedCategory
        : true;
      const matchesFabric = selectFabric
        ? product.fabric === selectFabric
        : true;
      const matchesSearch = product.name
        .toLowerCase()
        .includes(search.toLowerCase());
      return matchesCategory && matchesFabric && matchesSearch;
    });
    setSearchResults(results);
  }, [search, selectedCategory, selectFabric]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getAllProducts();
        setAllProducts(products);
      } catch (errorMessage) {
        toast.error(errorMessage);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <>
      <div
        className={`${styles.overlay} ${isOpen ? styles.open : ""}`}
        onClick={onClose}
      ></div>
      <div
        ref={sidebarRef}
        className={`${styles.searchSidebar} ${isOpen ? styles.open : ""}`}
      >
        {/* search text and cross icon  */}
        <div className={styles.header}>
          <h2>Filters</h2>
          <button onClick={onClose} className={styles.closeButton}>
            <img src={CrossIcon} alt="X" />
          </button>
        </div>

        {/* heading  */}
        <div className={styles.aboutText}>
          Refine your search with the following filters
        </div>
        {/* filter main content  */}

        <div className={styles.sidebar}>
          {/* Price Range */}
          <div className={styles.filterSection}>
            <h3>Price Range</h3>
            <p>₹0 - ₹{price}</p>
            <input
              type="range"
              min="0"
              max="2500"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className={styles.slider}
            />
          </div>
          {/* Collections */}
          <div className={styles.filterSection}>
            <div
              className={styles.dropdownList}
              onClick={() => setIsCollectionsOpen(!isCollectionsOpen)}
            >
              <h3>Collections</h3>
              <img
                src={DropdownIcon}
                alt="dropdown"
                className={isCollectionsOpen ? styles.rotate : ""}
              />
            </div>
            {isCollectionsOpen && (
              <div className={styles.dropdownContent}>
                <label>
                  <input type="checkbox" />
                  <span>Featured</span>
                </label>
                <label>
                  <input type="checkbox" />
                  <span>Best Sellers</span>
                </label>
                <label>
                  <input type="checkbox" />
                  <span>New Arrivals</span>
                </label>
              </div>
            )}
          </div>

          {/* Fabric */}
          <div className={styles.filterSection}>
            <div
              className={styles.dropdownList}
              onClick={() => setIsFabricOpen(!isFabricOpen)}
            >
              <h3>Fabric</h3>
              <img
                src={DropdownIcon}
                alt="dropdown"
                className={isFabricOpen ? styles.rotate : ""}
              />
            </div>
            {isFabricOpen && (
              <div className={styles.dropdownContent}>
                {[
                  "Silk",
                  "Cotton Silk",
                  "Georgette",
                  "Banarasi Silk",
                  "Patola Silk",
                  "Kanjivaram Silk",
                  "Linen",
                ].map((fabric) => (
                  <label key={fabric}>
                    <input type="checkbox" />
                    <span>{fabric}</span>
                  </label>
                ))}
              </div>
            )}
          </div>

          {/* Occasion */}
          <div className={styles.filterSection}>
            <div
              className={styles.dropdownList}
              onClick={() => setIsOccasionOpen(!isOccasionOpen)}
            >
              <h3>Occasion</h3>
              <img
                src={DropdownIcon}
                alt="dropdown"
                className={isOccasionOpen ? styles.rotate : ""}
              />
            </div>
            {isOccasionOpen && (
              <div className={styles.dropdownContent}>
                {["Wedding", "Festival", "Party", "Casual", "Office Wear"].map(
                  (occasion) => (
                    <label key={occasion}>
                      <input type="checkbox" />
                      <span>{occasion}</span>
                    </label>
                  )
                )}
              </div>
            )}
          </div>
        </div>

        {/* Apply button */}
        <div className={styles.applyButton}>
          <button>Clear All</button>
          <button>Apply Filters</button>
        </div>
      </div>
    </>
  );
};

export default FilterSidebar;
