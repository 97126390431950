import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { GET_PRODUCT_BY_CATEGORY } from "../Api/Apis";
import axios from "axios";
import styles from "./FullProducts.module.css";
import ProductCard from "../ProductCard/ProductCard";
import ProductDetailModal from "../../Modals/ProductDetails";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FilterIcon from "../../assets/filter.svg";
import FilterSidebar from "./FilterSidebar";
const FullProduct = () => {
  const { category } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page");
  const [sortOption, setSortOption] = useState("Featured");
  const [openFilters, setOpenFilters] = useState(false);
  const [FullProductData, setFullProductData] = useState(null);
  const [price, setPrice] = useState(2500);
  if (category) document.title = `${category} - Bandhej Hub`;

  const FetchFullProductList = async () => {
    try {
      const response = await axios.get(
        `${GET_PRODUCT_BY_CATEGORY}/${category}/${currentPage}`
      );

      setFullProductData(response.data);
      // setFullProductData(sortProducts(response.data));
    } catch (error) {
      console.log("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    FetchFullProductList();
  }, [currentPage, category]);

  const pageNumbers = [];
  for (let i = 1; i <= FullProductData?.totalPages; i++) {
    pageNumbers.push(i);
  }
  const handleSortSelection = (e) => {
    setSortOption(e.target.value);
  };

  // MODAL LOGIN
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  return (
    <>
      <div>
        <div className={styles.fullproductContainer}>
          <div className={styles.productDetailsTopContainerNavigation}>
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <MdOutlineKeyboardArrowRight />
            </div>
            <div>
              <Link to={`/${category}`}>{category}</Link>
            </div>
          </div>
          {/* filter and heading  */}
          {/* <div className={styles.fullproductHeading} id="pageTop">
            <div>
              <h2>{category}s</h2>
            </div>

            <div className={styles.fullproductHeadingFilterIcons}>
              <select name="" id="" onChange={handleSortSelection}>
                <option value="Featured">Featured</option>
                <option value="priceAccending">Price, low to hight</option>
                <option value="priceDecending"> Price, high to low</option>
                <option value="AtoZ">Alphabetically, A-Z</option>
                <option value="ZtoA">Alphabetically, Z-A</option>
                <option value="NewToOld"> Date, new to old</option>
                <option value="OldToNew"> Date, old to new</option>
              </select>

              <div
                className={styles.filterButton}
                onClick={() => {
                  setOpenFilters(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <img src={FilterIcon} alt="Filter" />
                <p>Filters</p>
              </div>
            </div>
          </div> */}
          {/* total product  */}
          {/* <div className={styles.totalProduct}>
            5 products found with 1 active filters
          </div> */}
          {/* selected filter  */}
          {/* <div className={styles.selectedFilter}>Active Filters:</div> */}
          {/* product listing  */}
          <div className={styles.fullProductFilterContainer}>
            {/* filter sidebar  */}
            {/* <div className={styles.sidebar}>
              <div className={styles.filterSection}>
                <h3>Collections</h3>
                <label>
                  <input type="checkbox" />
                  <span>Featured</span>
                </label>
                <label>
                  <input type="checkbox" />
                  <span>Best Sellers</span>
                </label>
                <label>
                  <input type="checkbox" />
                  <span>New Arrivals</span>
                </label>
              </div>

              <div className={styles.filterSection}>
                <h3>Price Range</h3>
                <p>₹0 - ₹{price}</p>
                <input
                  type="range"
                  min="0"
                  max="2500"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  className={styles.slider}
                />
              </div>

              <div className={styles.filterSection}>
                <h3>Fabric</h3>
                {[
                  "Silk",
                  "Cotton Silk",
                  "Georgette",
                  "Banarasi Silk",
                  "Patola Silk",
                  "Kanjivaram Silk",
                  "Linen",
                ].map((fabric) => (
                  <label key={fabric}>
                    <input type="checkbox" />
                    <span>{fabric}</span>
                  </label>
                ))}
              </div>

              <div className={styles.filterSection}>
                <h3>Occasion</h3>
                {["Wedding", "Festival", "Party", "Casual", "Office Wear"].map(
                  (occasion) => (
                    <label key={occasion}>
                      <input type="checkbox" />
                      <span>{occasion}</span>
                    </label>
                  )
                )}
              </div>
            </div> */}
            {/* product list  */}
            <div>
              {FullProductData?.length === 0 ? (
                <div className={styles.fullProductNotFound}>
                  <p>No products found</p>
                </div>
              ) : (
                <>
                  <div className={styles.fullproductListContainer}>
                    <div className={styles.fullproductList}>
                      {!FullProductData
                        ? Array(15)
                            .fill()
                            .map((_, index) => (
                              <div
                                key={index}
                                className={styles.productSkeletonLoading}
                              >
                                {/* Skeleton Image */}
                                <Skeleton height={150} />
                                {/* Skeleton Category */}
                                <Skeleton
                                  width={140}
                                  style={{ marginTop: "14px" }}
                                />
                                {/* Skeleton Title */}
                                <Skeleton
                                  width={150}
                                  style={{ marginTop: "8px" }}
                                />
                                {/* Skeleton Price */}
                                <Skeleton
                                  width={120}
                                  style={{ marginTop: "8px" }}
                                />
                                {/* Skeleton Buttons */}
                                <div className={styles.skeletonLoadingButton}>
                                  <Skeleton width={40} height={35} />
                                  <Skeleton width={40} height={35} />
                                </div>
                              </div>
                            ))
                        : FullProductData?.products?.map((product) => (
                            <ProductCard
                              key={product.productId}
                              product={product}
                              openModal={openModal}
                            />
                          ))}
                    </div>
                  </div>
                  {/* paginations  */}
                  <div className={styles.fullProductListPages}>
                    {/* Prev button */}
                    <div className={styles.PrevNextButton}>
                      {currentPage > 1 && (
                        <Link
                          to={`/${category}?page=${parseInt(currentPage) - 1}`}
                        >
                          Prev
                        </Link>
                      )}
                    </div>

                    {/* Page numbers */}
                    <div className={styles.pageNumbers}>
                      {pageNumbers.map((pageNumber) => (
                        <Link
                          key={pageNumber}
                          to={`/${category}?page=${pageNumber}`}
                        >
                          <p
                            className={`${styles.pageLink} ${
                              pageNumber == currentPage ? styles.activePage : ""
                            }`}
                          >
                            {pageNumber}
                          </p>
                        </Link>
                      ))}
                    </div>
                    {/* Next button */}
                    <div className={styles.PrevNextButton}>
                      {currentPage < pageNumbers.length && (
                        <Link
                          to={`/${category}?page=${parseInt(currentPage) + 1}`}
                        >
                          Next
                        </Link>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {isModalOpen && (
          <ProductDetailModal
            isOpen={isModalOpen}
            onClose={closeModal}
            selectedProduct={selectedProduct}
          />
        )}
        <FilterSidebar
          isOpen={openFilters}
          onClose={() => {
            setOpenFilters(false);
            document.body.style.overflow = "auto";
          }}
        />
      </div>
    </>
  );
};

export default FullProduct;
