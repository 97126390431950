import React from "react";
import styles from "./HeaderBanner.module.css";

const HeaderBanner = () => {
  return (
    <div className={styles.bannerContainer}>
      <img
        src="/assets/bhPosterHeader.jpg"
        // srcSet="/assets/bhPosterHeader-small.jpg 480w, /assets/bhPosterHeader-medium.jpg 768w, /assets/bhPosterHeader-large.jpg 1200w"
        sizes="(max-width: 480px) 480px, (max-width: 768px) 768px, 1200px"
        alt="Banner"
        loading="lazy"
      />
    </div>
  );
};

export default HeaderBanner;
