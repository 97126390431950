import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

// Your Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyAMJWsjUb_pnGP5uAycUBytbWuV7sIRL28",
  authDomain: "bandhejhub2824.firebaseapp.com",
  projectId: "bandhejhub2824",
  storageBucket: "bandhejhub2824.appspot.com",
  messagingSenderId: "892900216337",
  appId: "1:892900216337:web:9f1422ea6e4905a8edebb0",
  measurementId: "G-RP670S35VN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider, signInWithPopup, signOut };
