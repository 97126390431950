import React, { useContext, useState } from "react";
import styles from "./Navbar.module.css";
import {
  TfiSearch,
  TfiHeart,
  CiMenuFries,
  PiShoppingCart,
  CiUser,
} from "../../assets/Icons";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import LoginModal from "../../Modals/Login";
import OtpVerification from "../../Modals/OtpVerification";
import SearchSidebar from "./SearchSidebar";
import Sidebar from "./Sidebar";

function Navbar() {
  const { cartData, isLoginOpen, setIsLoginOpen, wishListData, accessToken } =
    useContext(UserContext);

  //  STATES
  const [stickyClass] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openLoginModal = () => {
    document.body.style.overflow = "hidden";
    setIsLoginOpen(true);
  };

  return (
    <>
      <div className={`${styles.navbarItem} ${stickyClass}`}>
        <div>
          <div className={styles.navbarMenuIcon}>
            <div>
              <i
                onClick={() => {
                  setIsMenuOpen(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <CiMenuFries />
              </i>
            </div>
            <div>
              <i
                onClick={() => {
                  setIsSearchOpen(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <TfiSearch />
              </i>
            </div>
          </div>
        </div>

        <div className={styles.brandName}>
          <Link to="/">
            <img src="/assets/bhlogo.png" alt="Loading.." />
          </Link>
        </div>

        <div className={styles.navItems}>
          <ul>
            <li className={styles.navItemUser}>
              {accessToken ? (
                <Link to="/account" style={{ fontSize: "25px" }}>
                  <CiUser />
                </Link>
              ) : (
                <div
                  onClick={openLoginModal}
                  style={{ fontSize: "25px", cursor: "pointer" }}
                >
                  <CiUser />
                </div>
              )}
            </li>
            <li className={styles.navItemWhitlist}>
              <Link to="/wishlist">
                <TfiHeart />
              </Link>
              <span>{wishListData?.length || 0}</span>
            </li>
            <li className={styles.navItemCart}>
              <Link to="/cart">
                <PiShoppingCart />
              </Link>
              <span>{cartData?.length || 0}</span>
            </li>
          </ul>
        </div>
      </div>
      <LoginModal isOpen={isLoginOpen} />
      <OtpVerification />
      <SearchSidebar
        isOpen={isSearchOpen}
        onClose={() => {
          setIsSearchOpen(false);
          document.body.style.overflow = "auto";
        }}
      />
      <Sidebar
        isOpen={isMenuOpen}
        onClose={() => {
          setIsMenuOpen(false);
          document.body.style.overflow = "auto";
        }}
      />
    </>
  );
}

export default Navbar;
