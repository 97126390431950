import backend_ref from "../Backend_ref";

export const GET_USER_PROFILE = `${backend_ref}/user/profile`;
export const UPDATE_USER_PROFILE = `${backend_ref}/user/update-profile`;
// CART
export const ADD_TO_CART = `${backend_ref}/cart`;
export const GET_CART_DATA = `${backend_ref}/cart`;
export const REMOVE_CART_DATA = `${backend_ref}/cart`;
//ORDER
export const GET_ORDER = `${backend_ref}/order`;
export const GET_ORDER_BY_ID = `${backend_ref}/order/getOrder`;
export const CANCEL_ORDER = `${backend_ref}/order/cancel`;
export const RATING_ORDER = `${backend_ref}/order/rating`;
//Promo Code
export const VALIDATE_PROMO_CODE = `${backend_ref}/promocode/validate`;
//Wishlist
export const GET_WISHLIST = `${backend_ref}/wishlist`;
export const ADD_WISHLIST = `${backend_ref}/wishlist`;
export const DELETE_WISHLIST = `${backend_ref}/wishlist`;
//News Letter
export const SUBSCRIBE_NEWS_LETTER = `${backend_ref}/newsletter/subscribe`;

// remove top apis
export const AUTH_TOKEN = `${backend_ref}/auth/token`;
//Admin
export const ADD_PRODUCT = `${backend_ref}/admin/addproduct`;
//User
export const USER_LOGOUT = `${backend_ref}/user/logout`;
export const SEND_OTP = `${backend_ref}/user/send-otp`;
export const VERIFY_OTP = `${backend_ref}/user/verify-otp`;
export const GOOGLE_LOGIN = `${backend_ref}/user/google-login`;
// ignore
export const USER_REGISTER = `${backend_ref}/user/register`;
export const USER_LOGIN = `${backend_ref}/user/login`;
export const USER_CHECK_EMAIL = `${backend_ref}/user/checkemail/forgotpassword`;
export const USER_FORGOTPASSWORD = `${backend_ref}/user/forgotpassword`;
export const USER_GOOGLE = `${backend_ref}/auth/google-login`;
export const USER_GOOGLE_SUCCESS = `${backend_ref}/auth/login/success`;

//Product
export const GET_PRODUCT_BY_ID = `${backend_ref}/product`;
export const GET_ALL_PRODUCT = `${backend_ref}/product`;
export const GET_PRODUCT_BY_TAG = `${backend_ref}/product/getproduct/tag`;
export const GET_PRODUCT_BY_CATEGORY = `${backend_ref}/product`;
export const PRODUCT_PRICE = `${backend_ref}/product/getproduct/price`;
export const GET_PRODUCT_REVIEWS = `${backend_ref}/product/variant/reviews`;

// PAYMENT
export const PAYMENT = `${backend_ref}/payment`;
export const PAYMENTVERIFY = `${backend_ref}/payment/verification`;
